<template>
	<section
		class="layout-center805"
	>

		<MapDevice
			:source-data="mapSource"
			:selected-areas-data="instance.areas"
			enable-geocoder
			:search-query="searchQuery"
			class="layout_block-full_width"
			:campaign-id="context.instance.id"
			:without-control="!instance.platforms || instance.platforms.length == 0"

			@update="updateMapAndStats"
			@searchListRequest="searchlistRequestHandler"
			@update:selectedAreasData="updateSelectedAreas"
		/>


		<div class="request_create-step03 cl-container" >
			<section
				class="request_create-step03--form cl-xs8"
			>

				<!-- Advanced settings switch -->
				<div
					:class="{
						disabled: !showAdvancedSettings,
					}"
					class="advanced-settings-switch"
				>
					<label
						class="label"
						for="advanced-settings-switch"
					>{{ $t('campaign.advanced_settings_label') }}</label>

					<nice-switch
						id="advanced-settings-switch"
						v-model="showAdvancedSettings"
						label=""
						caption-on=""
						caption-off=""
					/>
				</div>


				<!-- Payment Model -->
				<p
					v-if="enabledPaymentModels.length > 1"
					v-show="showAdvancedSettings"
					class="model-select-line"
				>
					<svg width="39" height="39" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="model-select-line__icon">
						<use :xlink:href="`#payment_model_icon__${instance.payment_model}`" />
					</svg>

					{{ $t('campaign.model_choose_string01') }}

					<NiceMenuContext
						:label="$t(`payment_models.${instance.payment_model}`)"
						class="model-select-line__inline-dd"
					>
						<nice-button-2
							v-for="model in enabledPaymentModels"
							:key="model"
							class="model-select-line__option"
							:class="{
								current: model === instance.payment_model,
							}"
							@click="setPaymentModel(model)"
						>
							{{ $t(`payment_models.${model}`) }}
						</nice-button-2>
					</NiceMenuContext>

				</p>


				<!-- Networks/platforms -->
				<div
					v-if="networks.length > 1"
					v-show="showAdvancedSettings"
					class="data_block"
				>
					<h3>
						{{ $t('campaign.platform_label') | capitalize }}
						<nice-hint :hint="$t('campaign.platform_hint')"/>
					</h3>

					<nice-spoiler
						:label="$t('campaign.all_platforms_label') | capitalize"
						:expand="isPlatformsSpoilerExpanded"
						:askConfirmation="true"
						:askConfirmationText="$t('campaign.reset_platforms_selection_confirmation_text')"
						class="nice-spoiler-platforms"
						@collapse="resetPlatforms"
					>
						<network-tree-check
							v-if="isMultiSPPMode"
							:platforms="instance.platforms"
							:networks="networks"
							@update:platforms="updatePlatforms"
						/>
						<nice-tree-check
							v-else
							v-model="instance.platforms"
							:tree="networks"
						/>
					</nice-spoiler>
					<p
						class="input--description"
						v-html="$t('campaign.selected_platforms', { num:  instance.platforms.length })"
					></p>
				</div>


				<!-- Address -->
				<div
					v-show="showAdvancedSettings"
					class="data_block"
				>
					<h3>
						{{ $t('campaign.address') | capitalize }}
					</h3>

					<select-areas
						v-model="instance.areas"
						:search-list-request="searchListRequest"
						:search-query.sync="searchQuery"
						:error="context.errors.areas"
					/>
				</div>


				<!-- Audience -->
				<device-choices
					v-show="showAdvancedSettings"
					:key="`devicechoices-${deviceChoicesKey}`"
					:payment-model="instance.payment_model"
					:platforms="instance.platforms"
					:age.sync="instance.age"
					:gender.sync="instance.gender"
					:environments.sync="instance.environments"
					:pois.sync="instance.pois"
					:resolutions.sync="instance.resolutions"
					:orientations.sync="instance.orientations"
					:device-types.sync="instance.device_types"
				/>


				<!-- Schedule -->
				<div class="data_block">
					<h3>{{ $t('campaign.budget_n_schedule_title') | capitalize }}</h3>

					<schedule
						:time-period.sync="instance.time_period"
						:time-range.sync="instance.time_range"
						:schedule.sync="instance.schedule"
						:show-advanced-settings="showAdvancedSettings"
						:min-date="new Date().toISOString()"

						:errors="{
							// time_period: {
							// 	start: context.errors.start_date,
							// 	end: context.errors.end_date,
							// },
							// schedule: context.errors.schedule,
						}"
					/>


					<!-- Message duration & rate -->
					<section
						v-show="showAdvancedSettings"
						class="duration-n-rate"
					>
						<nice-input
							v-model.number="instance.ad_duration"
							:label="$t('campaign.message_duration') | capitalize"
							type="number"
							min="1"
							max="300"
							step="1"
							:units="$t('campaign.sec')"
							:invalid="context.errors.ad_duration"
							required
							class="input--duration"
						/>

						<!-- :units="$t('campaign.per_hour')" -->
						<div>
							<nice-label
								:label="$t('campaign.frequency_limit') | capitalize"
								:hint="$t('campaign.per_device') | capitalize"

								class="ad-rate__label"
							/>
							<div class="ad-rate__input">
								<nice-input
									v-model.number="instance.ad_rate_per_hour"
									type="number"
									min="1"
									max="720"
									step="1"
									:invalid="context.errors.ad_rate_per_hour"
									required
									class="frequency-limit"
								/>
								<nice-select
									v-model="instance.ad_rate_multiplier"
									:options="adRateOptions"

									class="ad-rate__modifier"
								/>
							</div>
						</div>
						<!-- :label="$t('campaign.frequency_limit') | capitalize" -->
					</section>
					<!-- END: Message duration & rate -->

				</div>
				<!-- END: Schedule -->


				<!-- Budget -->
				<div class="data_block data_block--budget_and_max_bid">

					<nice-input
						ref="budget"
						v-model.number="instance.budget"
						:label="$t('campaign.budget')"
						:hint="$t('campaign.budget_hint')"
						type="number"
						:units="$options.filters.formatCurrency(instance.currency)"
						min="0.0001"
						step="0.0001"
						:invalid="context.errors.budget"
						required
						class="input--budget"
						@change="budgetChangeHandler"
					/>

					<div
						v-if="instance.owner_instance && instance.budget > instance.owner_instance.balance"
						class="control-row__message_errors">
							{{ $t('campaign.budget_more_than_balance') }}
					</div>

					<div class="input--description">
						<div
							v-if="instance.budget"
							v-html="$t('campaign.budget_desc_spend_to', {
								budget_to_spend: $options.filters.formatCash(instance.budget, instance.currency)
							})"
						/>
						<div
							v-if="currentSelection"
							v-html="$t('campaign.budget_desc_max_budget', {
								budget_max_limit: $options.filters.formatCash(currentSelection.total_max_budget, instance.currency)
							})"
						/>
					</div>


					<!-- Fixed placement -->
					<div
						v-show="showAdvancedSettings"
						class="fixed-placement"
					>
						<nice-checkbox
							v-model="instance.is_fixed_placement"
							@input="instance.max_bid = currentSelection.price.max"
						/>

						<span class="fixed-placement__label">{{ $t('campaign.fixed_placement') | capitalize }}</span>

						<nice-hint
							:hint="$t('campaign.fixed_placement_hint') | capitalize"
							class="fixed-placement__hint"
						/>
					</div>

					<nice-input
						v-if="!instance.is_fixed_placement"
						v-model.number="instance.max_bid"
						:label="$t('campaign.max_bid')"
						:hint="$t('campaign.max_bid_hint')"
						type="number"
						:invalid="context.errors.max_bid"
						required
						:units="$options.filters.formatCurrency(instance.currency)"
						min="0.0001"
						step="0.0001"
						class="input--max_bid"
						@change="maxBidChangeHandler"
					/>
					<div
						v-else
						class="fixed-cost__container"
					>
						<nice-label class="fixed-cost__label" :label="$t('campaign.fixed_cost') | capitalize" />
						<span class="fixed-cost__value">
							<span>{{ $options.filters.formatNumber(instance.max_bid) || '—' }}</span>
							<span class="fixed-cost__units">{{ instance.currency | formatCurrency }}</span>
						</span>
					</div>
					<p v-if="currentSelection" class="input--help_text" >
						<!-- до лучших времен -- {{ currentSelection.payment_model_coef }} -->
						{{ currentPaymentModel.label }}
						{{ $t('campaign.model_phrase', { seconds: $tc('campaign.seconds', instance.ad_duration) }) }}
					</p>
					<p
						v-if="currentSelection && !instance.is_fixed_placement"
						class="input--description"
						v-html="$t('campaign.bid_desc', {
							min_price: $options.filters.formatCash(currentSelection.price.min, instance.currency, 2),
							max_price: $options.filters.formatCash(currentSelection.price.max, instance.currency, 2),
						})"
					/>
				</div>


				<!-- Next button -->
				<nice-button-2
					filled
					icon-painted
					icon="arrow-14"
					icon-state="-90"
					icon-placement="end"
					class="next_button"
					:disabled="isAnyRequredFieldEmpty || isAnyErrorsFromBackend"
					@click="goToNext"
				>
					{{ $t('campaign.next_btn_label') }}
				</nice-button-2>

				<div class="control-row" >
					<span
						v-if="isAnyRequredFieldEmpty"
						class="control-row__message control-row__message"
					>{{ $t('errors.fill_required_fields') }}</span>

					<span
						v-if="isAnyErrorsFromBackend"
						class="control-row__message control-row__message_errors"
					>{{ $t('errors.resolve_errors') }}</span>
				</div>

			</section>

			<aside class="aside cl-xs4">
				<device-card-list-summary
					v-if="currentSelection"
					:plays="currentSelection.plays"
					:impressions="currentSelection.impressions"
					:inventory="currentSelection.inventory"
					:specs="currentSelection.specs"
					:paymentModel="instance.payment_model"
					:showAdditionalValue="true"
					:timePeriod="instance.time_period"
					:playsPerDay="currentSelection.plays_per_day"
					:devicesUptime="currentSelection.devices_uptime"

					class="summary"
				/>

				<div class="aside__button-wrapper">
					<nice-button-2
						v-tippy
						:content="$t('campaign.inventory_modal_hint')"
						filled
						icon-placement="end"
						:disabled="!instance.platforms || instance.platforms.length == 0"
						class="aside__modal-button"
						@click="isInventoryModalShowing = true"
					>
						{{ $t('campaign.open_inventory_modal') }}
					</nice-button-2>
				</div>

			</aside>
		</div>

		<InventoryDetailsModal
			v-if="isInventoryModalShowing"
			class="inventory-modal layout_block-full_width"
			:instance="context.instance"
			:current-selection="currentSelection"

			:showAdditionalValue="true"
			:playsPerDay="currentSelection.plays_per_day"
			:devicesUptime="currentSelection.devices_uptime"

			@update-required="updateStats"
			@close="isInventoryModalShowing = false"
		/>
	</section>
</template>


<script>
import _ from 'underscore';
import { mapState, mapActions, mapGetters } from 'vuex';

import apiDsp from '@/api/dsp/device';
import apiSsp from '@/api/ssp/device';
import { getDevicesFilter, objectToQuery } from '@/utilites';
import { request, getBaseApiUrl } from '@/api/request';

import DeviceCardListSummary from '@/components/device/card-list-summary';
import MapDevice from '@/components/map-device';
import SelectAreas from '@/components/device/select-areas';
import NiceHint from '@/ui/nice-hint';
import NiceInput from '@/ui/nice-input';
import NiceMenuContext from '@/ui/nice-menu-context';
import NiceSelect from '@/ui/nice-select';
import NiceCheckbox from '@/ui/nice-checkbox';
import NiceSpoiler from '@/ui/nice-spoiler';
import NiceTreeCheck from '@/ui/nice-tree-check';
import NiceSwitch from '@/ui/nice-switch.vue';

// partials
import AudienceCpm from './audience-cpm.vue';
import Schedule from './schedule.vue';
import DeviceChoices from './device-choices';
import InventoryDetailsModal from './inventory-details-modal';
import NetworkTreeCheck from './network-tree-check';

import {
	PAYMENT_MODEL_AD_PLAYS,
	PAYMENT_MODEL_CPM,
	PAYMENT_MODEL_CPV,
} from '@/constants/payment_models';


// milliseconds. 1 second is too short
const DEBOUNCE_TIMEOUT = 2000;
// after areas update
const SHORT_DEBOUNCE_TIMEOUT = 500;

const MODEL = Object.freeze({
	prop: 'value',
	event: 'update:value',
});


export default {
	name: 'Step03',


	model: MODEL,


	components: {
		DeviceCardListSummary,
		MapDevice,
		NiceHint,
		NiceInput,
		NiceMenuContext,
		NiceSelect,
		SelectAreas,
		NiceCheckbox,

		// eslint-disable-next-line vue/no-unused-components
		AudienceCpm,
		Schedule,
		DeviceChoices,
		InventoryDetailsModal,
		NetworkTreeCheck,

		NiceSpoiler,
		NiceSwitch,
		NiceTreeCheck
	},


	props: {
		stepCurrent: {
			type: Number
		},

		stepCount: {
			type: Number
		},

		goToNext: {
			type: Function
		},

		goToPrev: {
			type: Function
		},

		[MODEL.prop]: {
			type: Object,
			default: ()=>({}),
		},

		/*
		 * contains `instance`, `step`
		 */
		context: {
			type: Object,
			required: true,
		},
	},


	data() {
		return {
			PAYMENT_MODEL_AD_PLAYS,
			PAYMENT_MODEL_CPM,
			PAYMENT_MODEL_CPV,

			// inner value
			instance: {
				...this[MODEL.prop],
			},

			// dicts
			// TODO: OLD: ???
			// environmentList: [],
			// interestsAndActivitiesList: [],
			// lifeStageList: [],
			// intentList: [],
			// resolutionList: [],
			// orientationList: [],

			currentSelection: {
				// depends on payment_model
				'plays': {
					'value': 0,
					'total': 0,
				},
				'impressions': {
					'value': 0,
					'total': 0,
				},
				'plays_per_day': 0,
				'inventory': {
					'value': 0,
					'total': 0,
					'excel_url': '',
				},
				'price': {
					'min': 0,
					'max': 0,
					'avg': 0,
				},
				// [{type: 'monitor', resolution: '1920x1080', count: 21,}, ...]
				'specs': [],
				'devices_uptime': {
					'good_uptime': 0,
					'average_uptime': 0,
					'bad_uptime': 0,
				},
				'total_max_budget': 0,
			},

			mapSource: {
				'type': 'FeatureCollection',
				'features': [],
			},

			// search address
			searchQuery: '',
			searchListRequest: [],

			requiredFields: [
				// 'start_date',
				// 'end_date',
				'time_period',
				'time_range',
				'ad_duration',
				'ad_rate_per_hour',
				'payment_model',
				'budget',
				'max_bid',
			],

			// available networks
			networks: [],

			isPlatformsSpoilerExpanded: false,

			isInventoryModalShowing: false,

			showAdvancedSettings: false,

			// delay so we don't spam MapBox & our stats endpoints
			debouncedUpdateMapAndStats: _.debounce(this.updateMapAndStats, DEBOUNCE_TIMEOUT),
			// we need a shorter delay after a map area manipulation
			// + there is a delay in `devicesFilter` update (because of `this.context.instance` update?)
			shortDebouncedUpdateMapAndStats: _.debounce(this.updateMapAndStats, SHORT_DEBOUNCE_TIMEOUT),

			isMultiSPPMode: false,
		};
	},


	async created() {
		await Promise.all([
			this.$store.dispatch('extra/getPaymentModels'),
		]);
	},


	async mounted() {
		this.showLoader();

		// await this.updateStats();
		// await this.setMapSource();
		await this.shortDebouncedUpdateMapAndStats();

		// get networks
		this.$set(this, 'networks', await this.getAgencyNetworks());

		// if no network is selected - select all
		if (!this.instance.platforms || this.instance.platforms.length == 0) {
			this.resetPlatforms();
		}
	},


	computed: {
		...mapState('app', {
			appSide: 'side',
			enabledPaymentModels: 'enabledPaymentModels',
		}),

		...mapState('extra', [
			'paymentModels',
		]),

		...mapGetters('extra', [
			'getPaymentModelByValue',
			'getBroadcastTypeByValue',
		]),

		currentPaymentModel() {
			return this.getPaymentModelByValue(this.instance.payment_model);
		},

		devicesFilter() {
			// we need only deployed devices here
			let filter = getDevicesFilter(this.context.instance, true);
			return objectToQuery(filter);
		},

		isAnyRequredFieldEmpty() {
			return this.requiredFields.some(field => {
				let result = !this.instance[field];

				if (field === 'time_period') {
					let tp = this.instance.time_period;
					result = !(tp !== null && typeof tp === 'object' && tp.start && tp.end);
				}

				return result;
			});

		},

		adRateOptions() {
			return Object.freeze([
				{
					label: this.$t('campaign.for_hour', {value: 1}),
					value: 1,
				},
				{
					label: this.$t('campaign.for_minutes', {value: 30}),
					value: 2,
				},
				{
					label: this.$t('campaign.for_minutes', {value: 10}),
					value: 6,
				},
				{
					label: this.$t('campaign.for_minutes', {value: 5}),
					value: 12,
				},
				{
					label: this.$t('campaign.for_minutes', {value: 2}),
					value: 30,
				},
			]);
		},

		isAnyErrorsFromBackend() {
			return [
				'areas',
				'platforms',
				'age',
				'gender',
				'interests',
				'life_stages',
				'intents',
				'environments',
				'pois',
				'resolutions',
				'orientations',
				'device_types',
				//'time_period', // see below
				'start_date',
				'end_date',
				'time_range',
				'schedule',
				'ad_duration',
				'ad_rate_per_hour',
				'payment_model',
				'budget',
				'max_bid',
			].some(field => this.context.errors[field]);
		},

		deviceChoicesKey() {
			let key = 'none';
			if (this.instance.platforms && this.instance.platforms.length > 0) {
				key = this.instance.platforms.join('-');
			}

			return key;
		},

	},  // END: computed


	methods: {
		...mapActions('devices', ['getStats']),


		showLoader() {
			// HACK: we have a very bad defaults calculation mechanic
			let loader = this.$loading.show();
			setTimeout(() => {
				loader.hide();
			}, 3000);

			return loader;
		},


		/**
		 * We update `budget` automatically unless use set it manually
		 */
		budgetChangeHandler(event) {
			this.instance = {
				...this.instance,
				'is_budget_manually_set': true,
			};
		},


		/**
		 * We update `max_bid` automatically unless use set it manually
		 */
		maxBidChangeHandler(event) {
			this.instance = {
				...this.instance,
				'is_max_bid_manually_set': true,
			};
		},


		async getAgencyNetworks() {
			const networks = await Promise.resolve(this.appSide === 'ssp'
				? this.$store.state.app.sspNetworks
				: this.$store.state.app.dspNetworks);

			if (!networks || !networks.length) return [];

			const isMultiSPPMode = this.getAndAdjustMultiSPPMode(networks);

			return networks.map(network => ({
				value: network.id,
				label: network.name,
				title: network.description,
				parent: isMultiSPPMode ? network.ssp_agency.name : null,
			}));
		},


		async updateMapAndStats() {
			const loader = this.showLoader();

			await this.setMapSource();
			await this.updateStats();

			loader.hide();
		},


		/**
		 * Update map and stats "immediately" after map areas change
		 */
		async updateSelectedAreas(areas) {
			this.instance.areas = areas;
			await this.shortDebouncedUpdateMapAndStats();
		},


		async updateStats() {
			let loading = this.$loading.show();

			const res = await this.getStats({
				filter: this.devicesFilter,
			});
			this.$set(this, 'currentSelection', res);

			this.updateBudget(res);
			this.updateMaxBid(res);

			loading.hide();
		},


		/**
		 * Automatically update `budget` if it isn't set manually
		 */
		updateBudget(stats) {
			if (this.instance.is_budget_manually_set) {
				return;
			}

			// Round up to 0.01, example of valid price is 0.18 dollars
			var rounded_budget = Math.round(stats.total_max_budget * 100) / 100;
			if (this.instance.budget !== rounded_budget) {
				this.instance.budget = rounded_budget;
			}
		},


		/**
		 * Automatically update `max_bid` if it isn't set manually
		 *
		 * NB: separate branches for a "not fixed placement" are intentional
		 */
		updateMaxBid(stats) {
			let max_bid = 0;

			// check if current max_bid is number at all
			if (Number.isInteger(parseInt(this.instance.max_bid))) {
				max_bid = parseFloat(this.instance.max_bid.toFixed(2));
			}
			else {
				this.instance.max_bid = max_bid;
			}

			// for a fixed placement - always use max price
			if (this.instance.is_fixed_placement) {
				max_bid = parseFloat(stats.price.max.toFixed(2));
			}
			// not fixed placement...
			else {
				// ... and manually set and < then a min price - min price
				if (this.instance.is_max_bid_manually_set && max_bid < stats.price.min) {
					max_bid = parseFloat(stats.price.min.toFixed(2));
				}
				// ... and manually set and > then a min price - don't change
				// ... and not manually set - min price
				// TODO: might be max price?
				else if (!this.instance.is_max_bid_manually_set) {
					max_bid = parseFloat(stats.price.min.toFixed(2));
				}
			}

			// `$set()` doesn't work - there might be no `max_bid`
			if (max_bid !== this.instance.max_bid) {
				this.instance = {
					...this.instance,
					max_bid,
				};
			}

		},


		async setMapSource() {
			const apiPath = (this.appSide == 'ssp' ? apiSsp : apiDsp).getPrefix().slice(0,-1);

			let filter = this.devicesFilter;
			// HACK: to show all devices if platform is not selected
			if (this.devicesFilter.indexOf('platforms=') == -1) {
				filter += '&platforms=all';
			}

			const url = `${getBaseApiUrl()}${apiPath}.geojson${filter}`;

			const result = await request(url);

			this.$set(this, 'mapSource', result);
		},


		/**
		 * What for?
		 * To pass promise with results of search from 'MapDevice' component to 'select-areas' component
		 */
		searchlistRequestHandler(results) {
			this.searchListRequest = results;
		},


		/**
		 * Close platforms spoiler and select all platforms
		 */
		resetPlatforms() {
			this.$set(this.instance, 'platforms', []);
		},

		setPaymentModel(model) {
			this.instance.payment_model = model;
			if (model != 'cpv') {
				this.clearCPVParams();
			}
		},

		clearCPVParams() {
			this.$set(this.instance, 'gender', 'all');
			this.$set(this.instance, 'age', [0, 100]);
		},

		updatePlatforms(platforms) {
			this.instance.platforms = platforms;
		},

		getAndAdjustMultiSPPMode(network) {
			const parents = new Set(network.map(item => item.ssp_agency.name));
			this.isMultiSPPMode = parents.size > 1 ? true : false;
			if (this.isMultiSPPMode) {
				this.showAdvancedSettings = true;
				this.isPlatformsSpoilerExpanded = true;
			}
			return this.isMultiSPPMode;
		},
	},


	watch: {
		[MODEL.prop]: {
			deep: true,
			async handler(newValue, oldValue) {
				if (_.isEqual(newValue, this.instance)) {
					return;
				}

				this.instance = JSON.parse(JSON.stringify(newValue));
			}
		},

		instance: {
			deep: true,
			async handler(newValue, oldValue) {
				if (_.isEqual(newValue, this[MODEL.prop])) {
					return;
				}

				// TODO: BUG: `id` might disappear from the instance here
				this.$emit(MODEL.event, JSON.parse(JSON.stringify(newValue)));
			},
		},

		/**
		 * Update request/campaign stats on filter change
		 *
		 * debounced
		 */
		devicesFilter: {
			deep: true,
			handler() {
				this.debouncedUpdateMapAndStats();
			},
		},
	},  // END: watch

};
</script>


<style lang="sass" >
.payment_model-hint
	font-size: $fsz__smaddle

	> *:first-child
		margin-top: 0

	ul
		margin: 0
		padding: 0
		list-style-position: inside

		li
			margin-top: 1em
</style>

<style lang="sass" scoped >
$column_indent: 45px
$row--indent: 20px
$row--width: 330px

h3
	display: flex
	flex-direction: row
	flex-wrap: nowrap
	justify-content: flex-start
	align-items: center
	align-content: center

	.nice-hint
		margin-left: 7px

.map
	height: 372px
	margin-bottom: $cmpnt-rc__content-margin-default


.advanced-settings-switch
	display: flex
	align-items: center
	justify-content: space-between
	height: 50px
	background-color: var(--main_lighter_color)
	border-radius: 10px
	// margin-top: 30px
	margin-bottom: 50px

	&.disabled
		background-color: #F5F5F5

		&:hover
			background-color: var(--main_lighter_color)

			label
				color: var(--main_color)

		label
			color: #919191

	label
		margin: 0 20px 0 20px
		padding: 0
		font-weight: 500
		font-size: 16px
		line-height: 19px
		color: var(--main_color)

	label.label
		flex-grow: 1
		padding-top: 10px
		padding-bottom: 10px

.model-select-line
	position: relative
	font-weight: bold
	font-size: 14.5px
	line-height: (17 / 14.5 * 1em)
	padding-left: 60px
	margin-top: 50px
	margin-bottom: 50px
	box-sizing: border-box


.model-select-line__icon
	position: absolute
	display: block
	width: 39px
	height: 39px
	left: 0
	color: var(--main_color)
	top: calc((100% - 39px) / 2)

.model-select-line__inline-dd
	display: inline-block
	font-size: inherit
	line-height: inherit
	border-bottom: 2px solid var(--main_color)

	::v-deep
		.ni_menu_context--button-toogle-dropdown
			font-size: inherit
			font-weight: inherit
			text-transform: inherit
			line-height: inherit

.model-select-line__option
	font-size: inherit
	line-height: inherit

.model-select-line__inline-input
	display: inline-block
	text-align: right
	appearance: textfield
	border: none
	border-bottom: 2px solid var(--main_color)
	color: var(--main_color)
	font-family: inherit
	font-size: inherit
	line-height: inherit
	font-weight: inherit
	width: 4.5em
	margin: 0 .5em
	padding: 0

.model-select-line__error-icon
	vertical-align: middle
	margin-left: .4em

.request_create-step03--card
	position: sticky
	top: 20px

.data_block
	display: flex
	flex-direction: column
	flex-wrap: nowrap
	justify-content: flex-start
	align-items: stretch

	//margin-top: $cmpnt-rc__content-margin-default
	margin-bottom: $cmpnt-rc__content-margin-default * 1.3

	> *
		margin-bottom: $row--indent

		&:first-child
			margin-top: 0

		&:last-child
			margin-bottom: 0

	h3
		font-weight: normal
		font-size: $fsz__new__section-title

	.nice-spoiler
		box-sizing: border-box
		width: 100%
		max-width: 380px
		margin: 0

		&.ni_slider--container
			margin-bottom: $row--indent / 2

		&.nice-spoiler-platforms
			margin-bottom: $row--indent / 2

.platform-select,
.time_period
	max-width: $row--width

// .select_areas
// 	margin-right: $column_indent


.input--budget,
.input--max_bid
	max-width: 225px

	::v-deep
		.ni_input--input,
		.ni_input--units
			// margin-top: 30px
			font-size: $fsz__title
			font-weight: 300

.input--help_text
	margin-top: 0

	font-size: $fsz__section-title
	color: var(--text_2_color)

.input--description
	margin-top: 0
	margin-bottom: 30px

.next_button
	width: 100%
	height: 40px
	margin-bottom: 10px

.control-row
	display: flex
	flex-direction: row
	flex-wrap: nowrap
	justify-content: flex-start
	// margin-right: $column_indent
	align-items: center

	& > *
		margin-right: 15px

.control-row__message
	display: inline-block
	// color: var(--text_2_color)
	color: $nice_color-red

.control-row__message_errors
	color: $nice_color-red

.input--ad_flight::v-deep
	border-bottom: transparent


.aside
	position: sticky
	top: 40px
	box-sizing: border-box
	padding-left: 20px

.aside__button-wrapper
	display: flex
	align-items: center
	margin-top: 20px

	& > *
		margin-right: 10px
		&:last-child
			margin-right: 0

.aside__modal-button
	flex-basis: 100%


.input--duration
	width: 132px

.duration-n-rate
	display: flex
	flex-direction: row

	.frequency-limit
		margin-left: 40px
		width: 42px

		&::before
			content: '<'
			color: $nice_color-darkgray
			position: absolute
			bottom: 7px
			left: -10px

.inventory-modal
	& ::v-deep > *
		max-width: 1320px
//
// Deep
//

::v-deep
	.mapboxgl-ctrl-geocoder.mapboxgl-ctrl
		visibility: hidden
		display: none

	.ni_input--autocomplete
		width: 200%

	.ni_input--units
		color: var(--text_2_color)

.fixed-placement
	display: flex
	flex-direction: row
	align-items: center

	& > *
		margin-right: 10px

.fixed-placement__label
	margin-bottom: 5px

	font-size: 16px
	line-height: 19px

.fixed-placement__hint
	margin-bottom: 5px

.fixed-cost__container
	display: flex
	flex-direction: column

.fixed-cost__value
	width: 81px
	height: 23px

	font-weight: 300
	font-size: 20px
	line-height: 23px

.fixed-cost__units
	color: #919191
	margin-left: 5px

.ad-rate__input
	display: flex
	flex-direction: row

.ad-rate__label
	margin-left: 40px

.ad-rate__modifier
	margin-left: 10px
</style>
