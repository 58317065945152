<template>
	<tr class="inventory-table-row">
		<td>
			<span class="device-actions">
				<!--
					inventory_modal_add_to_blacklist
					inventory_modal_add_to_blacklist_hint
					inventory_modal_remove_from_blacklist_hint
					inventory_modal_add_to_whitelist
					inventory_modal_add_to_whitelist_hint
					inventory_modal_remove_from_whitelist_hint
				 -->
				<nice-button-2
					v-tippy="{ appendTo: () => $el }"
					:content="$t(`inventory_modal.${type === 'black' ? 'remove_from' : 'add_to'}_blacklist_hint`)"
					palette="red"
					bordered
					:filled="type === 'black'"
					:disabled="isBusy"
					type="button"
					class="device-action-button"
					role="checkbox"
					:aria-checked="type === 'black'"
					aria-label="blacklist"

					@click="handleActionButtonClick('black')"
				>{{$t('inventory_modal.add_to_blacklist')}}</nice-button-2>
				<nice-button-2
					v-tippy="{ appendTo: () => $el }"
					:content="$t(`inventory_modal.${type === 'white' ? 'remove_from' : 'add_to'}_whitelist_hint`)"
					bordered
					:filled="type === 'white'"
					:disabled="isBusy"
					type="button"
					class="device-action-button"
					role="checkbox"
					:aria-checked="type === 'white'"
					aria-label="whitelist"

					@click="handleActionButtonClick('white')"
				>{{$t('inventory_modal.add_to_whitelist')}}</nice-button-2>
			</span>
		</td>
		<td>{{ device.location_name || '—' }}</td>
		<td>{{ device.city || '—' }}</td>
		<td>{{ device.address || '—' }}</td>
		<td>{{ device.name || '—' }}</td>
		<td>
			<div v-if="device.resolution" class="resolution-wrapper" >
				<nice-icon-2 icon="resolution-14" />
				<span>{{ device.resolution }}</span>
			</div>
			<template v-else >&mdash;</template>
		</td>
		<td>{{ device.count_screen || '—' }}</td>
		<td :style="{ color: uptime.color }"> {{ uptime.value }} </td>
		<td>{{ device.ad_plays_count || '—' }}</td>
		<td>{{ ots || '—' }}</td>
		<td class="nobr">{{ Math.round(cost.min) }}&nbsp;&mdash;&nbsp;{{ Math.round(cost.max) }}</td>
	</tr>
</template>


<script>
import {
	addDevicesToManualFilter,
	removeDevicesFromManualFilter,
} from '@/api/dsp/manual-inventory-filter';

import {
	PAYMENT_MODEL_CPV,
} from '@/constants/payment_models';

import { uptime } from '@/utilites';

export default {
	name: 'InventoryTableRow',


	props: {
		device: {
			type: Object,
			required: true,
		},

		context: {
			type: Object,
			required: true,
			default: () => ({
				currency: null,
				payment_model: null,
				request: null,
			}),
		},
	},


	data() {
		return {
			isBusy: false,
			type: this.device.manual_filter_type,
		};
	},


	computed: {
		ots() {
			if (this.context.payment_model === PAYMENT_MODEL_CPV) {
				return this.device.cpv_count;
			}
			return this.device.cpm_count;
		},


		/**
		 * ad_plays_cost
		 * cpm_cost
		 * cpv_cost
		 */
		cost() {
			let cost = this.device[this.context.payment_model + '_cost'];
			if (!cost) {
				cost = {min: '', max: ''};
			}
			return cost;
		},

		uptime() {
			return uptime(this.device);
		}
	},


	methods: {
		async handleActionButtonClick(type) {
			this.isBusy = true;
			try {
				if (type === this.type) {
					// remove rule on a repeating click
					this.type = null;
					await this._removeDeviceFromManualFilter();
				} else {
					// add rule
					this.type = type;
					await this._addDeviceToManualFilter(type);
				}
			} catch (error) {
				const data = error.response && error.response.data;
				this.$log.error(`Error ${error.response && error.response.status}. Failed rule change.`, data);
				this.isBusy = false;
				throw error;
			}

			this.requireUpdate();
			this.isBusy = false;
		},

		async _addDeviceToManualFilter(type) {
			await addDevicesToManualFilter(
				this.context.request_id,
				type,
				[this.device.id],
			);
		},

		async _removeDeviceFromManualFilter() {
			await removeDevicesFromManualFilter(
				this.context.request_id,
				[this.device.id],
			);
		},

		requireUpdate() {
			this.$emit('update-required');
		},
	},


	watch: {
		'device.manual_filter_type'(value) {
			this.type = value;
		}
	}
};
</script>

<style lang="sass" scoped>
.device-actions
	display: inline-flex
	// flex-wrap: wrap

	& > *
		margin-right: 10px
		&:last-child
			margin-right: 0

.resolution-wrapper
	display: inline-flex
	align-items: center
</style>
