<template>
	<Transition name="modal-transition">

		<NiceModal
			@close="close"
		>

			<section class="balance-add" >

				<header class="header">
					<NiceIcon2
						class="header__icon"
						icon="money"
					/>

					<h2 class="header__title">{{ $t('dashboard.add_balance') | capitalize }}</h2>

					<div class="header__actions">
						<NiceButton2
							bordered
							:disabled="isBusy"
							@click="close"
						>{{$t('common.close')}}</NiceButton2>
					</div>

				</header>

				<InvoiceBox
					v-if="paymentMethod && paymentMethod.type == 'invoicebox'"
					:payment-method="paymentMethod"
				/>
				<Stripe
					v-else-if="paymentMethod && paymentMethod.type == 'stripe'"
					:payment-method="paymentMethod"
				/>
				<Asaas
					v-else-if="paymentMethod && paymentMethod.type == 'asaas'"
					:payment-method="paymentMethod"
				/>
				<NoPaymentMethod
					v-else
				/>

			</section>

		</NiceModal>
	</Transition>
</template>


<script>
import NiceModal from '@/ui/nice-modal';

import { getPaymentMethods } from '@/api/billing';

import NoPaymentMethod from './payment_methods/no-payment-method';
import Asaas from './payment_methods/asaas';
import InvoiceBox from './payment_methods/invoice-box';
import Stripe from './payment_methods/stripe';


export default {
	name: 'BalanceAddPage',


	components: {
		NiceModal,
		NoPaymentMethod,
		Asaas,
		InvoiceBox,
		Stripe,
	},


	data() {
		return {
			isBusy: false,

			paymentMethods: [],
			paymentMethod: null,

		};
	},


	async mounted() {
		// get available payment methods
		this.paymentMethods = await getPaymentMethods();
		if (this.paymentMethods.length > 0) {
			this.paymentMethod = this.paymentMethods[0];
		}
	},


	methods: {

		close() {
			this.$router.push({
				name: 'billing',
				hash: '#' + this.$t('documents.invoices').toLowerCase(),
			});
		},

	},
};
</script>


<style lang="sass" scoped>
.balance-add
	padding: 30px 40px
	box-sizing: border-box
	// min-height: 675px
	max-width: 675px
	width: 100%
	overflow: auto


.header
	display: flex
	align-items: center
	margin-bottom: 30px

	& > *
		margin-left: 15px

		&:first-child
			margin-left: 0

	.header__icon
		--ni-icon-sign: var(--main_color)
		width: 26px
		height: 26px
		flex-shrink: 0

	.header__title
		font-size: 16px
		line-height: 20px
		// margin: calc((34px - 20px) / 2) 0
		font-weight: normal

	.header__actions
		display: flex
		align-items: center
		justify-content: flex-end
		margin-left: auto

		& > *
			margin-left: 15px
</style>
