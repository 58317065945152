<template>
	<section
		class="review-tab layout-center805"
	>
		<!-- <main class="ads-wrapper">
			<template v-if="instance.ads && instance.ads.length" >
				<creative-preview
					v-for="(ad, index) in instance.ads"
					:key="`${ad.id}-ad-${ad.type}`"
					class="ad-preview"

					:creative="instance.ads[index]"
					:context="{
						instance
					}"

					:format="stats.specs"

					auditable

					@audit-update="onAuditUpdate"
				/>
			</template>


			<template v-else >
				<h2 class="message">🤷 {{ $t('There are no ads') }}</h2>
			</template>
		</main> -->





		<section
			v-for="format in creativeList"
			:key="`${format.type}-${format.resolution}`"
			:class="['creative-wrapper', { 'creative-wrapper-other': format.type == 'other' }]"
		>
			<!-- TODO: HACK: rename monitor -> screen here -->
			<h2 class="format-title">
				<!--TODO: update using types as translation keys
				{{ format.type === 'monitor' ? 'screen' : format.type | capitalize }}-->

				<template
					v-if="format.type === 'videoOrImage'"
				>
					<nice-icon-2
						class="format-title__icon"
						icon="resolution-14"
						:state="+format.resolution.split('x')[0] >= +format.resolution.split('x')[1] ? 0 : 90"
					/>

					{{ format.type === 'videoOrImage' ? $t('campaign.monitor') : format.type | capitalize }}<!--
					-->{{ ', ' + format.resolution }}<!--
					-->{{ ', ' + (
							+format.resolution.split('x')[0] >= +format.resolution.split('x')[1]
							? $t('horizontal')
							: $t('vertical')
						)
					}}<!--
					--><span class="format-title__count">, {{ $tc('campaign.devices', format.count)}}</span>
				</template>

				<template
					v-else-if="format.type === 'audio'"
				>
					<nice-icon-2
						class="format-title__icon"
						icon="audio"
					/>
					{{ $t('campaign.audio') | capitalize }}<!--
					--><span class="format-title__count">, {{ $tc('campaign.devices', format.count)}}</span>
				</template>

				<template v-else >
					<nice-icon-2
						class="format-title__icon"
						icon="draft"
					/>
					{{ $t('campaign.other') | capitalize }}
				</template>
			</h2>


			<template v-if="format.ads && format.ads.length" >
				<div 
					v-for="(ad, index) in format.ads" :key="`${ad.id}`"
					class="creative-preview-wrapper"
				>
					<creative-preview
						v-if="ad.video !== null"
						:key="`${ad.id}`"
						:format="format"
						:creative="format.ads[index]"
						:context="{ instance }"
						class="creative-preview"

						auditable

						@audit-update="onAuditUpdate"
						@remove="remove"
					/>
					<ul 
						v-if="ad.attachments && ad.attachments.length" 
						class="attachments-list"
					>
						<li 
							v-for="attachment in sortedAttachments(ad.attachments)" 
							:key="attachment.id"
						>
							<a :href="attachment.file" target="_blank">
								{{ attachment.file_name }}
							</a>
						</li>
					</ul>
					<div 
						v-if="isDSP && format.type != 'other' && !hasRestrictedStatus(instance)"
						class="add-buttons"
					>
						<add-creative
							v-if="ad.video==null"
							:adId="ad.id"
							:context="{ instance }"
							:format="format"
							:disabled="isUploading(ad.id)"
							clean-on-upload

							@update:creativeData="pushCreative"
							@upload:start="setUploading(ad.id, true)"
							@upload:end="setUploading(ad.id, false)"
						/>
						<add-document 
							:adId="ad.id"
							:campaignId="instance.id"
							:group="`${format.type}-${format.resolution}`"
							:disabled="isUploading(ad.id)"

							@update:creativeData="pushCreative"
							@upload:start="setUploading(ad.id, true)"
							@upload:end="setUploading(ad.id, false)"
						/>
					</div>
				</div>
			</template>
			<template v-else>
				<div v-if="isDSP">
					<add-creative
						:context="{ instance }"
						:format="format"
						clean-on-upload
						:disabled="isUploading(getGroup(format))"

						@update:creativeData="pushCreative"
						@upload:start="setUploading(getGroup(format), true)"
						@upload:end="setUploading(getGroup(format), false)"
					/>
					<add-document 
						:campaignId="instance.id"
						:group="getGroup(format)"
						:disabled="isUploading(getGroup(format))"

						@update:creativeData="pushCreative"
						@upload:start="setUploading(getGroup(format), true)"
						@upload:end="setUploading(getGroup(format), false)"
					/>
				</div>
				<template v-else >
					<h2 class="message">{{ $t('campaign.no_creative_specified') }}</h2>
				</template>
			</template>

		</section>
	</section>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';

import CreativePreview from '@/components/creative/creative-preview';
import AddCreative from '@/components/creative/add-creative';
import AddDocument from '@/components/creative/add-document';
import { hasRestrictedStatus } from '@/constants/audit-status-codes';


const CREATIVE_TYPE_BY_BROADCASTING_POINT_TYPE = Object.freeze({
	monitor: 'videoOrImage',
});


export default {
	name: 'TabCreative',

	components: {
		AddCreative,
		AddDocument,
		CreativePreview
	},

	props: {
		instance: {
			type: Object,
			required: true,
		},

		stats: {
			type: Object,
			required: true,
		},

		resource: {
			type: String,
			default: 'request',
			validator: resource => [
				'request',
				'campaign',
			].includes(resource),
		},
	},

	data() {
		return {
			uploadingStatus: {},
		};
	},

	computed: {
		...mapState('app', { appSide: 'side' }),

		isDSP() {
			return this.appSide === 'dsp';
		},

		creativeList() {
			if (!this.stats || !this.stats.specs) {
				return [];
			}

			// clone specs, mixing in `ads[]` field, sorting by broadcasting points count
			const specs = this.stats.specs.map(spec => ({
				...spec,
				type: CREATIVE_TYPE_BY_BROADCASTING_POINT_TYPE[spec.type] || spec.type,
				ads: [],
			})).sort((aSpec, bSpec) => bSpec.count - aSpec.count);

			// safe clone creative list
			const adList = this.instance && this.instance.ads ? this.instance.ads.slice() : [];

			// drop if no ads
			if (!adList.length) {
				return specs;
			}

			// fill specs `ads[]` field with creatives
			const lostSpec = { type: 'other', ads: [] };

			while (adList.length) {
				const ad = adList.pop();
				let spec = specs.find(spec => {
					if (ad.video !== null) {
						return spec.type.toLowerCase().includes(ad.type) && ad.video.screen_resolution === spec.resolution;
					} else if (ad.group) {
						return ad.group.toLowerCase().includes(spec.resolution);
					}
				});

				if (!spec) {
					lostSpec.ads.push(ad);
					continue;
				}

				spec.ads.push(ad);
			}

			if (lostSpec.ads.length) {
				specs.push(lostSpec);
			}

			return specs;
		},
	},

	methods: {
	  ...mapActions('creative', ['removeFromCampaign']),

		hasRestrictedStatus,

		onAuditUpdate() {
			this.$emit('update');
		},

	  /**
	   * Creative delete handler
	   */

		async remove(creative) {
			let res = await this.removeFromCampaign({
				id: creative.id,
				campaign: this.instance.id,
			});

			const id = res?.id || creative.id;
			this.updateOrRemoveAd(res, id);

			this.setCampaignOnPauseIfNoCreatives();

			return res;
		},

		updateOrRemoveAd(updatedAd, id) {
			const index = this.instance.ads.findIndex(ad => ad.id === id);

			if (index !== -1) {
				if (updatedAd) {
					Vue.set(this.instance.ads, index, updatedAd);
				} else {
					this.instance.ads.splice(index, 1);
				}
			}
		},

	  setCampaignOnPauseIfNoCreatives() {
		  if (this.instance.ads.length === 0 || this.instance.ads.every(ad => ad.video === null)) {
			  this.$emit('pause:campaign');
		  }
	  },

	  pushCreative(creative) {
		  if (!creative) return null;

		  // check if `instance` has ads
		  if (!this.instance.ads) {
			  this.$set(this.instance, 'ads', []);
		  } else {
			  // check if creative already in ads
			  let ad = this.instance.ads.find(ad => ad.id === creative.id);
			  if (ad) {
				  this.instance.ads.splice(this.instance.ads.indexOf(ad), 1, creative);
				  return null;
			  }
		  }

		  this.instance.ads.unshift(creative);
		},

		sortedAttachments(attachments) {
			return attachments.slice().sort((a, b) => new Date(b.ctime) - new Date(a.ctime));
		},

		getGroup(format) {
			return `${format.type}-${format.resolution}`;
		},

		setUploading(id, isUploading) {
			this.$set(this.uploadingStatus, id, isUploading);
		},

		isUploading(id) {
			return this.uploadingStatus[id] || false;
		},
	},

};
</script>

<style lang="sass" scoped>
.ad-preview
	&:not(:last-child)
		margin-bottom: 30px

.message
	font-size: 25px
	font-weight: 100
	margin: -16px 0 16px

.format-title
	// display: flex
	// align-items: center
	margin-bottom: 26px

.format-title__icon
	display: inline-block
	margin-right: 7px
	vertical-align: middle

.nice-icon__resolution-14
	&.state-90
		margin-top: -3px


.format-title__count
	color: var(--text_2_color)


.campaign-creative
	margin: $cmpnt-rc__content-margin-top auto 0
	max-width: $cmpnt-rc__content-width

.creative-wrapper
	display: flex
	flex-direction: column
	margin-bottom: 30px

.creative-preview
	// & + .creative-preview
	// 	margin-top: 15px

	&:nth-of-type(even)
		background: none

.creative-wrapper-other .creative-preview-wrapper
	margin-bottom: 10px

.add-buttons
	margin-top: 10px
	
.attachments-list
	margin: 0
	
.attachments-list li a
	color: var(--main_color)
	text-decoration: none
	margin-right: 1px

.attachments-list li a:hover,
.attachments-list li a:focus,
.attachments-list li a:active
	color: var(--main_color)
</style>
