<template>
	<nice-button-2
		v-if="collapsed"

		icon="plus-17"
		class="nice-spoiler ni_slider--button_expand"
		@click="collapsed = !collapsed"
	>{{ label }}</nice-button-2>

	<div
		v-else
		class="nice-spoiler ni_slider--container"
	>
		<div class="ni_slider--row_title" >
			<h3
				class="ni_slider--title"
				v-html="label"
			/>
			<nice-button-2
				icon="basket-14"
				class="ni_slider--button_collapse"
				@click="clickHandler"
			/>
		</div>

		<slot />
	</div>
</template>


<script>
export default {
	name: 'NiceSpoiler',


	props: {
		label: { type: String },

		askConfirmation: {
			type: Boolean,
			default: false,
		},

		askConfirmationText: {
			type: String,
			default: 'Are you sure?',
		},

		// expand on init
		expand: Boolean,
	},


	data() {
		return {
			collapsed: !this.expand,
		};
	},


	watch: {
		// collapsed(newValue, oldValue) {
		// 	this.collapsed = newValue;
		// },

		collapsed(newValue, oldValue) {
			if (newValue) {
				this.$emit('collapse');
			} else {
				this.$emit('expand');
			}
		},
	},


	methods: {
		clickHandler() {
			if (this.askConfirmation && !window.confirm(this.askConfirmationText)) {
				return;
			}
			
			this.collapsed = !this.collapsed;
		},
	},
};
</script>


<style lang="sass" scoped>

.ni_slider--container
	+default__border

	box-sizing: border-box
	padding: $indent_x

.ni_slider--row_title
	display: inline-flex

	flex-direction: row
	flex-wrap: nowrap
	justify-content: space-between
	align-items: stretch
	align-content: stretch

	margin: 0
	width: 100%

	.ni_slider--title
		margin: 0

		flex-grow: 1
		flex-shrink: 1

	.ni_slider--button_collapse
		$size: 30px
		$icon_size: 14px

		flex-grow: 0
		flex-shrink: 0

		margin: -4px ( $size - $icon_size ) / -2 - 2px 0 0
		width: $size
		height: $size
		padding: ( $size - $icon_size ) / 2

		.nice-icon
			margin-right: 0
</style>
