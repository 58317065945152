<template>
	<section
		class="review-tab layout-center805"
	>
		<main class="wrapper">
			<template v-if="!instance.is_external">
				<div class="details cl-container cl-pad-no_sides">
					<div class="detail detail-huge cl-row cl-pad" >
						<template v-if="isNameEditModeOn && appSide === 'dsp'" >
							<div>
								<nice-input
									v-model="nameTempValue"
									class="inline-edit-field inline-edit-field_huge"
									:label="$t('campaign.campaign_name') | capitalize"
								/>
								<nice-button-2
									class="inline-edit-button"
									icon="check"
									@click="applyNameChanges"
								/>
								<nice-button-2
									class="inline-edit-button"
									icon="error"
									@click="cancelNameChanges"
								/>
							</div>
						</template>
						<template v-else >
							<nice-label class="label" :label="$t('campaign.campaign_name') | capitalize" />
							<span class="value" >
								<span>{{ nameValue }}</span>
								<nice-button-2
									v-if="appSide === 'dsp' && !hasRestrictedStatus(instance)"
									class="inline-edit-button"
									icon="pencil-13"
									@click="editName"
								/>
							</span>
						</template>
					</div>
					<div class="detail detail-huge cl-xs3 cl-pad" >
						<nice-label class="label" :label="$t('campaign.ad_flight') | capitalize" />
						<span class="value">
							<span>{{ adFlight || '—' }}</span>
							<span class="units">{{ $tc('campaign.days_won', adFlight) }}</span>
						</span>
					</div>
					<div class="detail detail-huge cl-xs3 cl-pad" >
						<nice-label class="label" :label="$t('campaign.ad_duration') | capitalize" />
						<span class="value">
							<span>{{ instance.ad_duration || '—' }}</span>
							<span class="units">{{ $t('campaign.sec') }}</span>
						</span>
					</div>
					<div class="detail detail-huge cl-xs3 cl-pad" >
						<nice-label class="label" :label="$t('campaign.time_period') | capitalize" />
						<span class="value">
							<span>{{ timePeriod || '—' }}</span>
						</span>
					</div>
				</div>

				<hr class="line">
			</template>

			<div class="details cl-container cl-pad-no_sides">
				<!-- company (agency-owner) -->
				<div class="detail cl-xs3 cl-pad" >
					<nice-label class="label" label="DSP" />
					<span class="value" >{{ instance.owner_instance ? instance.owner_instance.name : '—' }}</span>
				</div>

				<!-- created by -->
				<div class="detail cl-xs3 cl-pad" >
					<nice-label class="label" :label="$t('campaign.created_by') | capitalize" />
					<span class="value" >{{ createdBy }}</span>
				</div>

				<!-- email -->
				<div class="detail cl-xs3 cl-pad" >
					<nice-label class="label" :label="$t('campaign.email') | capitalize" />
					<a
						v-if="contactEmail"
						:href="`mailto:${ contactEmail }`"
						target="_blank"
						class="value value_email value_link"
					>
						{{ contactEmail }}
					</a>
					<span v-else class="value">&ndash;</span>
				</div>

				<!-- contact phone -->
				<div class="detail cl-xs3 cl-pad" >
					<nice-label class="label" :label="$t('campaign.contact_number') | capitalize" />
					<!-- <a
						v-if="contactPhoneNumber"
						:href="`tel:${ contactPhoneNumber }`"
						target="_blank"
						class="value value_phone value_link"
					>
						{{ contactPhoneNumber }}
					</a>
					<span v-else class="value">&ndash;</span> -->
					<span class="value" >{{ contactPhoneNumber }}</span>
				</div>

				<!-- address -->
				<div class="detail cl-xs3 cl-pad" >
					<nice-label class="label" :label="$t('campaign.address') | capitalize" />
					<span class="value multiline">
						{{ instance.owner_instance && instance.owner_instance.address ? instance.owner_instance.address : '—' }}
					</span>
				</div>

				<!-- seller id -->
				<!-- <div class="detail cl-xs3 cl-pad" >
					<nice-label class="label" :label="$t('campaign.client_id') | capitalize" />
					<span class="value">
						{{ instance.contract ? '#' + instance.contract.number : '—' }}
					</span>
				</div> -->

				<!-- advertiser name -->
				<div class="detail cl-xs3 cl-pad" >
					<nice-label class="label" :label="$t('advertiser.advertiser_column_label') | capitalize" />
					<span class="value">
						<NiceIcon2
							v-if="instance.advertiser"
						  :icon="advertiserStatusIcon"
						  class="status-icon"
						/>
						<a
							v-if="instance.advertiser"
							class="value_link"

							@click="editAdvertiser(instance.advertiser_instance)"
						>
							{{ instance.advertiser_instance.name }}
						</a>
						<span v-else>—</span>
					</span>
				</div>

				<!-- industry -->
				<div class="detail cl-xs3 cl-pad" >
					<nice-label class="label" :label="$t('campaign.industry') | capitalize" />
					<span class="value">{{ instance.industry_instance ? instance.industry_instance.name : '—' }}</span>
				</div>

			</div>
		</main>
	</section>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';

import NiceInput from '@/ui/nice-input';
import { hasRestrictedStatus } from '@/constants/audit-status-codes';

import {
	STATUS_AWAITING_APPROVAL,
	STATUS_APPROVED,
} from '@/constants/advertiser.js';


// helper status
const ADVERTISER_STATUS_MIXED = 'mixed';


const ADVERTISER_STATUS_ICONS = {
	[ADVERTISER_STATUS_MIXED]: 'hint',
	[STATUS_AWAITING_APPROVAL]: 'warn',
	[STATUS_APPROVED]: 'play',
};


function formatDate(date) {
	return moment(date).format('DD MMM`YY');
}


export default {
	name: 'TabDetails',


	components: {
		NiceInput,
	},


	props: {
		instance: {
			type: Object,
			required: true,
		},

		resource: {
			type: String,
			default: 'request',
			validator: resource => [
				'request',
				'campaign',
			].includes(resource),
		},
	},


	data() {
		return {
			nameValue: this.instance.name,
			nameTempValue: this.instance.name,
			isNameEditModeOn: false,
		};
	},


	computed: {
		...mapState('app', { appSide: 'side' }),

		adFlight() {
			let start = this.instance.start_date;
			let end = this.instance.end_date;
			let diff = moment(end).diff(moment(start), 'days');

			if (isNaN(diff)) {
				return 0;
			}

			// moment.diff works like this `20191001-20191002 == 1`. we need 2
			return diff + 1;
		},


		timePeriod() {
			let start = this.instance.start_date;
			let end = this.instance.end_date;

			return `${ start ? formatDate(start) : '⋯' } — ${ end ? formatDate(end) : '⋯' }`;
		},


		createdBy() {
			if (!this.instance.created_by) {
				return '—';
			}

			const { first_name, last_name } = this.instance.created_by;

			return [first_name, last_name].join(' ') || '—';
		},


		contactPhoneNumber() {
			const author = this.instance.created_by;
			const owner = this.instance.owner_instance;
			const phone = author && author.phone || owner && owner.phone || null;
			return phone || '—';
		},


		contactEmail() {
			const author = this.instance.created_by;
			const owner = this.instance.owner_instance;
			const email = author && author.email || owner && owner.email || null;
			return email;
		},

		advertiserStatusIcon() {
			let status = ADVERTISER_STATUS_MIXED;
			if (
				this.instance.advertiser_instance
				&& this.instance.advertiser_instance.status
			) {
				status = this.instance.advertiser_instance.status;
			}

			return ADVERTISER_STATUS_ICONS[status];
		},
	},


	methods: {
		...mapActions('campaign', {
			'updateCampaign': 'change',
		}),

		hasRestrictedStatus,

		async applyNameChanges() {
			this.nameValue = this.nameTempValue;

			const data = {
				name: this.nameValue
			};

			try {
				await this.updateCampaign({
					id: this.instance.id,
					data: data,
				});
			} catch (error) {
				this.$log.error(error);
				this.cancelNameChanges();
				throw error;
			}

			this.$emit('update');

			this.isNameEditModeOn = false;
		},

		cancelNameChanges() {
			this.nameTempValue = this.nameValue;
			this.isNameEditModeOn = false;
		},

		editName() {
			this.isNameEditModeOn = true;
		},

		/**
		 * HACK: scroll top - some tippy positioning problem
		 */
		editAdvertiser(advertiser) {
			window.scrollTo(0, 0);

			this.$router.push({
			  name: 'advertiser-edit',
			  params: {
			    advertiserId: advertiser.id,
			  },
			});
		},
	},


	watch: {
		['instance.name'](value, old) {
			this.nameValue = value;
			this.nameTempValue = value;
		},
	}
};
</script>


<style lang="sass" scoped>
.line
	margin-bottom: 54px

.details
	margin-bottom: -35px
	& > *
		margin-bottom: 35px

.detail
	display: flex
	flex-direction: column
	flex-grow: 0

.value
	display: flex
	align-items: center
	width: 100%
	line-height: 18px
	margin: (30px - 18px) / 2 0
	text-overflow: ellipsis
	overflow: hidden

	&:not(.multiline)
		white-space: nowrap
		overflow: hidden

	& > *
		margin-left: .5em

	& > :first-child
		margin-left: 0

	& > .units,
	& > .shaded
		color: var(--text_2_color)


.value_link
	display: block
	color: var(--main_color)
	text-decoration: none
	cursor: pointer

	&:hover
		text-decoration: underline


.detail-huge
	& > .label
		font-size: 11px

	& > .value
		font-weight: 300
		font-size: 16px


.inline-edit-field
	display: inline-block
	width: 125px

	& ::v-deep .ni_input--units
		color: var(--text_2_color)


.inline-edit-field_huge
	margin-top: 2px

	&::v-deep
		.ni_label--label
			margin-bottom: 4px
			line-height: 24px
			font-size: 11px

		.ni_input--input,
		.ni_input--units
			font-weight: 300
			font-size: 16px


.inline-edit-button
	max-height: 21px
	min-height: unset
	height: 21px
	box-sizing: border-box
	margin-left: 13px

.status-icon
	margin-right: 10px
</style>
