/**
 * OPTIONS REQUEST STUB
 */
import _ from 'underscore';


const getLoadingKey = (agency_id) => `store/agency_${agency_id}/device_filters/requesting`;

const INITIAL_OPTIONS = {
	resolutions: [],
	uptime: [],
	orientations: [],
	surface_types: [],
	payment_models: [],
	audience_interests: [],
};


export default {
	namespaced: true,

	state: {
		options: INITIAL_OPTIONS,
	},

	mutations: {
		setOptions(state, data) {
			state.options = data;
		},
	},

	getters: {
		isLoading(state, rootState, rootGetters) {
			return Boolean(rootState.agency && rootGetters['app/inProgress'][getLoadingKey(rootState.app.agency)]);
		}
	},

	actions: {
		/**
		 * Request filter options e.g. `resolution`, `surface_type`,
		 * `payment_models`, etc.
		 */
		getOptions: async function getOptions({ state, commit, rootState, dispatch }, force) {
			if (!state.options || !state.options.length || force) {
				let loadingKey = getLoadingKey(rootState.app.agency);
				commit('app/pushLoading', loadingKey, { root: true });

				// get and commit options
				let options = { ...INITIAL_OPTIONS };

				// getLoadingKey(rootState.app.agency, state.query)

				options.resolutions = (await dispatch('devices/getResolutions', {}, { root: true }));
				options.resolutions.unshift({ value: '---', label: 'All' });

				options.uptime = [
					{
						label: 'inventory_modal.good_uptime_value',
						value: 'good'
					},
					{
						label: 'inventory_modal.average_uptime_value',
						value: 'average'
					},
					{
						label: 'inventory_modal.bad_uptime_value',
						value: 'bad'
					},
				];
				options.uptime.unshift({ value: '---', label: 'All' });

				options.payment_models = (await dispatch('extra/getPaymentModels', {}, { root: true }))
					.map(model => _.pick(model, ['value', 'label']));
				options.payment_models.unshift({ value: '---', label: 'All' });

				options.surface_types = await dispatch('extra/getBroadcastTypes', {}, { root: true });
				options.surface_types.unshift({ value: '---', label: 'All' });

				options.orientations = await dispatch('extra/getOrientations', {}, { root: true });
				options.orientations.unshift({ value: '---', label: 'All' });

				options.statuses = [
					{
						label: 'ssp.on',
						value: 'true'
					},
					{
						label: 'ssp.off',
						value: 'false'
					},
				];
				options.statuses.unshift({ value: '---', label: 'All' });

				commit('setOptions', options);

				// remove options loading status
				commit('app/removeLoading', loadingKey, { root: true });
			}

			return this.options;
		},
	},
};
