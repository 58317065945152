import { render, staticRenderFns } from "./change-password.vue?vue&type=template&id=e0efa4a2&scoped=true"
import script from "./change-password.vue?vue&type=script&lang=js"
export * from "./change-password.vue?vue&type=script&lang=js"
import style0 from "./change-password.vue?vue&type=style&index=0&id=e0efa4a2&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0efa4a2",
  null
  
)

export default component.exports