<template>
	<section>
		<nice-spoiler
			:label="$t('campaign.int_and_act_label') | capitalize"
			:expand="isInterestsSpoilerExpanded"
			:askConfirmation="true"
			:askConfirmationText="$t('campaign.clear_selection_confirmation_text')"
			@collapse="resetInterests"
		>
			<nice-tree-check
				v-if="isTreesLoaded"
				v-model="innerInterests"
				:tree="interestsAndActivitiesList"
			/>
			<span v-else>{{ $t('common.loading') | capitalize }}&hellip;</span>
		</nice-spoiler>

		<nice-spoiler
			:label="$t('campaign.life_stage_label') | capitalize"
			:expand="isLifeStagesSpoilerExpanded"
			:askConfirmation="true"
			:askConfirmationText="$t('campaign.clear_selection_confirmation_text')"
			@collapse="resetLifeStages"
		>
			<nice-tree-check
				v-if="isTreesLoaded"
				v-model="innerLifeStages"
				:tree="lifeStageList"
			/>
			<span v-else>{{ $t('common.loading') | capitalize }}&hellip;</span>
		</nice-spoiler>

		<nice-spoiler
			:label="$t('campaign.intent_label') | capitalize"
			:expand="isIntentsSpoilerExpanded"
			:askConfirmation="true"
			:askConfirmationText="$t('campaign.clear_selection_confirmation_text')"
			@collapse="resetIntents"
		>
			<nice-tree-check
				v-if="isTreesLoaded"
				v-model="innerIntents"
				:tree="intentList"
			/>
			<span v-else>{{ $t('common.loading') | capitalize }}&hellip;</span>
		</nice-spoiler>
	</section>
</template>


<script>
import _ from 'underscore';

import NiceSpoiler from '@/ui/nice-spoiler';
import NiceTreeCheck from '@/ui/nice-tree-check';


export default {
	name: 'AudienceCpm',


	components: {
		NiceSpoiler,
		NiceTreeCheck,
	},


	props: {
		interests: {
			type: Array,
			default: () => [],
		},

		lifeStages: {
			type: Array,
			default: () => [],
		},

		intents: {
			type: Array,
			default: () => [],
		},
	},


	data() {
		return {
			innerInterests: this.interests || [],
			innerLifeStages: this.lifeStages || [],
			innerIntents: this.intents || [],

			// dicts
			interestsAndActivitiesList: [],
			lifeStageList: [],
			intentList: [],

			isTreesLoaded: false,

			isInterestsSpoilerExpanded: !!(this.interests && this.interests.length),
			isLifeStagesSpoilerExpanded: !!(this.lifeStages && this.lifeStages.length),
			isIntentsSpoilerExpanded: !!(this.intents && this.intents.length),
		};
	},


	methods: {
		resetInterests() {
			this.$set(this, 'innerInterests', []);
		},

		resetLifeStages() {
			this.$set(this, 'innerLifeStages', []);
		},

		resetIntents() {
			this.$set(this, 'innerIntents', []);
		},
	},


	watch: {
		interests: {
			deep: true,
			handler(value, old) {
				// compare with this.innerValue
				if (_.isEqual(value, this.innerInterests)) {
					return;
				}

				this.$set(this, 'innerInterests', value);
			},
		},

		lifeStages: {
			deep: true,
			handler(value, old) {
				// compare with this.innerValue
				if (_.isEqual(value, this.innerLifeStages)) {
					return;
				}

				this.$set(this, 'innerLifeStages', value);
			},
		},

		intents: {
			deep: true,
			handler(value, old) {
				// compare with this.innerValue
				if (_.isEqual(value, this.innerInstance)) {
					return;
				}

				this.$set(this, 'innerIntents', value);
			},
		},

		innerInterests: {
			handler(value, old) {
				if (_.isEqual(value, this.interests)) {
					return;
				}

				this.$emit('update:interests', value);
			},
		},

		innerLifeStages: {
			handler(value, old) {
				if (_.isEqual(value, this.lifeStages)) {
					return;
				}

				this.$emit('update:lifeStages', value);
			},
		},

		innerIntents: {
			handler(value, old) {
				if (_.isEqual(value, this.intents)) {
					return;
				}

				this.$emit('update:intents', value);
			},
		},
	},


	async created() {
		await Promise.all([
			this.$asyncSet(this, 'intentList', this.$store.dispatch('devices/getIntents')),
			this.$asyncSet(this, 'interestsAndActivitiesList', this.$store.dispatch('devices/getInterestsAndActivities')),
			this.$asyncSet(this, 'lifeStageList', this.$store.dispatch('devices/getLifeStages')),
		]);
		this.isTreesLoaded = true;
	},
};
</script>


<style lang="sass" scoped>
.nice-spoiler
	box-sizing: border-box
	width: 100%
	max-width: 380px
	margin: 0

	&.ni_slider--container
		margin-bottom: 10px
</style>
