export const PENDING_AUDIT = 1;
export const PRE_APPROVED = 2;
export const APPROVED = 3;
export const DENIED = 4;
export const CHANGED_RESUBMISSION_REQUESTED = 5;
export const EXPIRED = 6;
export const DRAFT = 691;
export const PAUSED = 692;
export const FINISHED = 693;
export const ARCHIVED = 694;
export const DELETED = 695;
// Extented statuses only for UI
export const PENDING_AUDIT_EXTENDED_APPROVED = 531;
export const PARTIALLY_APPROVED_EXTENDED_APPROVED = 532;


// all codes
export const AUDIT_STATUS_CODES = {
	PENDING_AUDIT,
	PRE_APPROVED,
	APPROVED,
	DENIED,
	CHANGED_RESUBMISSION_REQUESTED,
	EXPIRED,
	DRAFT,
	PAUSED,
	FINISHED,
	ARCHIVED,
	DELETED,
	PENDING_AUDIT_EXTENDED_APPROVED,
	PARTIALLY_APPROVED_EXTENDED_APPROVED
};


// only campaign
export const CAMPAIGN_AUDIT_STATUSES = {
	[APPROVED]: 'campaign_approved',
	[PAUSED]: 'campaign_paused',
	[FINISHED]: 'campaign_finished',
	[ARCHIVED]: 'campaign_archived',
	[PENDING_AUDIT]: 'campaign_pending_audit',
	[DENIED]: 'campaign_denied',
	[DELETED]: 'campaign_deleted',
	[PENDING_AUDIT_EXTENDED_APPROVED]: 'pending_audit',
	[PARTIALLY_APPROVED_EXTENDED_APPROVED]: 'partially_playing'
};


// only request: value: label
export const REQUEST_AUDIT_STATUSES = {
	[DRAFT]: 'request_draft',
	[PENDING_AUDIT]: 'request_pending_audit',
	[PRE_APPROVED]: 'request_pre_approved',
	[APPROVED]: 'request_approved',
	[ARCHIVED]: 'request_archived',
	[DENIED]: 'request_denied',
	[DELETED]: 'request_deleted',
};


export default AUDIT_STATUS_CODES;

const NO_CHANGE_STATUSES = [
	ARCHIVED,
	DELETED,
	FINISHED
];

export function hasRestrictedStatus(instance) {
	return instance.status?.some(audit => NO_CHANGE_STATUSES.includes(audit.status));
}
