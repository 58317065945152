import { parsePalette } from '@/ui/nice-palette';


/**
 * Override logo, colors, etc for WL domains
 *
 * TODO: colors MUST be in #hex notation
 */
export function enableUIOverrides(Vue, store, overrides) {
	Vue.prototype.$isWhitelabel = overrides.domain;

	let palette = parsePalette();
	if (overrides.main_color) {
		palette.main_color = overrides.main_color;
		// 50 is 0.3 alpha in hex
		palette.main_lighter_color = `${palette.main_color}50`;
		// 33 is 0.2 alpha in hex
		palette.disabled_color = `${palette.main_color}33`;
	}
	if (overrides.secondary_color) {
		palette.secondary_color = overrides.secondary_color;
		palette.secondary_disabled_color = `${palette.secondary_color}33`;
	}

	// set enabled payment models
	if (overrides.enabled_payment_models) {
		store.commit('app/setEnabledPaymentModels', overrides.enabled_payment_models);
	}

	Object.defineProperty(Vue.prototype, '$palette', { value: palette });
	Object.defineProperty(Vue.prototype, '$uioverrides', { value: overrides });

	// Title & description
	let title = overrides.html_title || process.env.VUE_APP_DEFAULT_TITLE;
	const platformSide = store.state.app.side;
	if (['dsp', 'ssp'].includes(platformSide)) {
		title = `${platformSide.toUpperCase()}: ${title}`;
	}
	let description = overrides.html_description || process.env.VUE_APP_DEFAULT_DESCRIPTION;

	const head = document.head || document.getElementsByTagName('head')[0];

	document.getElementsByTagName('title')[0].text = title;

	let descriptionElement = null;
	for (var el of document.getElementsByTagName('meta')) {
		if (el.name.toLowerCase() == 'description') {
			descriptionElement = el;
			break;
		}
	}
	if (!descriptionElement) {
		descriptionElement = document.createElement('meta');
		descriptionElement.name = 'description';
		head.appendChild(descriptionElement);
	}
	descriptionElement.content = description;

	// CSS vars
	let css = `
	:root {
		--main_color: ${palette.main_color};
		--main_lighter_color: ${palette.main_lighter_color};
		--disabled_color: ${palette.disabled_color};
		--secondary_color: ${palette.secondary_color};
		--secondary_disabled_color: ${palette.secondary_disabled_color};
	}`;

	const style = document.createElement('style');
	head.appendChild(style);
	style.type = 'text/css';
	style.appendChild(document.createTextNode(css));
}
