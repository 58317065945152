<template>
	<section>
		<nice-spoiler
			:label="$t('campaign.age_label') | capitalize"
			:expand="isAgeSpoilerExpanded"
			:askConfirmation="true"
			:askConfirmationText="$t('campaign.clear_selection_confirmation_text')"
			@collapse="resetAge"
		>
		<p class="title">
			<nice-label :label="$t('campaign.age_range') | capitalize" />
			<span class="value"> {{ age[0] }} &nbsp;&mdash; {{ age[1] }}</span>
		</p>
			<nice-range
				v-model="innerAge"
				:axis="{ start: 18, end: 64, step: 1 }"
			>
				<template #viz="options">
					<nice-range-viz v-bind="options" />
				</template>
				<nice-range-legend-item placement="start" label="18—24" />
				<nice-range-legend-item placement="value" position="31" label="25—34" />
				<nice-range-legend-item placement="center" label="35—44" />
				<nice-range-legend-item placement="value" position="52" label="45—54" />
				<nice-range-legend-item placement="end" label="55—64" />
			</nice-range>
		</nice-spoiler>

		<nice-spoiler
			:label="$t('campaign.gender_label') | capitalize"
			:expand="isGenderSpoilerExpanded"
			:askConfirmation="true"
			:askConfirmationText="$t('campaign.clear_selection_confirmation_text')"
			@collapse="resetGender"
		>
			<nice-radio
				v-model="innerGender"
				:caption="$t('campaign.male') | capitalize"
				name="gender"
				value="male"
			/>
			<nice-radio
				v-model="innerGender"
				:caption="$t('campaign.female') | capitalize"
				name="gender"
				value="female"
			/>
			<nice-radio
				v-model="innerGender"
				:caption="$t('campaign.all') | capitalize"
				name="gender"
				value="all"
			/>
		</nice-spoiler>
	</section>
</template>

<script>
import _ from 'underscore';

import NiceRange from '@/ui/nice-range';
import NiceRadio from '@/ui/nice-radio';
import NiceSpoiler from '@/ui/nice-spoiler';
import { NiceRangeLegendItem, NiceRangeViz } from '@/ui/nice-range';


const DEFAULT_AGE_RANGE = [0, 100];


export default {
	name: 'AudienceCpv',

	components: {
		NiceRadio,
		NiceRange,
		NiceRangeLegendItem,
		NiceRangeViz,
		NiceSpoiler,
	},


	props: {
		age: {
			type: Array,
			default: () => DEFAULT_AGE_RANGE
		},

		gender: {
			type: String,
			default: () => 'all'
		},
	},


	data() {
		return {
			innerAge: this.age || DEFAULT_AGE_RANGE,
			innerGender: this.gender,

			isAgeSpoilerExpanded: false,
			isGenderSpoilerExpanded: false,
		};
	},


	methods: {
		resetAge() {
			this.$set(this, 'innerAge', DEFAULT_AGE_RANGE);
		},


		resetGender() {
			this.innerGender = 'all';
		},
	},


	watch: {
		age: {
			deep: true,
			handler(value, old) {
				if (_.isEqual(value, this.innerAge)) {
					return;
				}

				this.$set(this, 'innerAge', value);
			},
		},

		gender: {
			handler(value, old) {
				if (value == this.innerGender) {
					return;
				}

				this.$set(this, 'innerGender', value);
			},
		},

		innerAge: {
			handler(value, old) {
				if (_.isEqual(value, this.age)) {
					return;
				}

				this.$emit('update:age', value);
			},
		},

		innerGender: {
			handler(value, old) {
				if (value == this.gender) {
					return;
				}

				this.$emit('update:gender', this.innerGender);
			},
		},

	},


	created() {
		// Currently API returns request instance with `age` field === [0, 100]
		// So age spoiler will always be extended
		// Waiting for api update
		this.isAgeSpoilerExpanded = !this.age || !_.isEqual(this.age, DEFAULT_AGE_RANGE);
		this.isGenderSpoilerExpanded = this.gender !== 'all';
	},
};
</script>


<style lang="sass" scoped>
.nice-spoiler
	box-sizing: border-box
	width: 100%
	max-width: 380px
	margin: 0

	&.ni_slider--container
		margin-bottom: 10px
</style>
