<template>
	<div>

		<div class="cl-row cl-pad-no_sides">
			<NiceSelect
				v-if="currentLegalType === 'unset'"
				v-model="methodType"
				:required="true"
				:placeholder="$t('billing.choose_payment_type') + '...'"
				:options="paymentOptions"
				:label="$t('billing.payment_method_label')"
				class="cl-pad cl-xs6"
			/>
			<template
				v-else
			>
				<NiceLabel
					:label="$t('billing.payment_method_label')"
					class="cl-pad cl-xs12"
				/>
				<div class="cl-pad cl-xs6">{{ methodTypeLabel }}</div>
			</template>
		</div>

		<div class="cl-row cl-pad-no_sides">
			<NiceInput
				v-model="paymentName"
				:label="nameLabel"
				:required="true"
				class="cl-pad cl-xs12"
			/>
		</div>

		<!-- legal entity -->
		<div
			v-if="methodType === METHOD_TYPE_BANK_TRANSFER || currentLegalType === 'legal'"
			class="cl-container cl-pad-no_sides"
		>

			<div class="cl-row">
				<NiceInput
					ref="inn"
					v-model="paymentTaxNumber"
					:validator="innValidate"
					:required="true"
					:invalid="errors.wrong_vat_number"
					:label="$t('billing.payment_tax_number_label')"
					class="cl-pad cl-xs6"

					@input="() => errors = {}"
				/>
			</div>

			<div class="cl-row">
				<NiceInput
					ref="address"
					v-model="paymentLegalAddress"
					:required="true"
					:label="$t('billing.payment_legal_address_label')"
					class="cl-pad cl-xs12"
				/>
			</div>

		</div><!-- END: legal entity -->


		<!-- legal and physical entity -->
		<div class="cl-row cl-pad-no_sides">
			<NiceInput
				ref="email"
				v-model="paymentEmail"
				:required="true"
				:validator="emailValidate"
				:invalid="errors.email"
				label="email"
				class="cl-pad cl-xs6"

				@input="() => errors = {}"
			/>

			<NiceInput
				ref="phone"
				v-model="paymentPhone"
				:required="true"
				:validator="phoneValidate"
				:invalid="errors.wrong_phone_number"
				:label="$t('billing.payment_phone_label')"
				placeholder="+7 111 2223344"
				class="cl-pad cl-xs6"

				@input="() => errors = {}"
			/>
		</div>


		<div class="cl-row cl-pad-no_sides">
			<NiceInput
				ref="amount"
				v-model="amountExcludingVAT"
				:required="true"
				:label="$t('billing.amount')"
				type="number"
				min="0"
				:units="currentCurrency"
				class="cl-pad cl-xs6"
			/>
			<!-- @change="amountExcludingVATChangeHandler" -->

			<!-- <NiceInput
				v-model="amountIncludingVAT"
				:label="$t('billing.amount_including_vat_label')"
				type="number"
				min="0"
				:units="currentCurrency"
				class="cl-pad cl-xs6"
			/> -->
			<!-- @change="amountIncludingVATChangeHandler" -->
		</div>


		<div class="buttons">
			<NiceButton2
				:disabled="isBusy"
				palette="gray"

				@click="close"
			>{{$t('common.close')}}</NiceButton2>

			<NiceButton2
				:disabled="!isOkToPay || !allInputsValid"

				@click="paymentHandler"
			>{{$t('billing.add_balance_button')}}</NiceButton2>
		</div>

	</div>
</template>


<script>
import { mapState, mapGetters /*, mapActions*/ } from 'vuex';

import legalEntityAPI from '@/api/legal';
import { getAddBalanceFormData } from '@/api/billing';

import { emailValidator, innValidator, phoneValidatorNumbersAndPlus } from '@/ui/utils/validators';
import { convertError } from '@/ui/utils/errors';

import NiceSelect from '@/ui/nice-select';
import NiceInput from '@/ui/nice-input';


const METHOD_TYPE_CARD = 'card';
const METHOD_TYPE_BANK_TRANSFER = 'bank_transfer';


export default {
	name: 'InvoiceBox',


	components: {
		NiceSelect,
		NiceInput,
	},


	props: {
		paymentMethod: {
			type: Object,
			required: true,
		},
	},


	data() {
		return {
			isBusy: false,


			// invoicebox payment methods
			// TODO: move somewhere?
			METHOD_TYPE_CARD,
			METHOD_TYPE_BANK_TRANSFER,

			legalEntity: {},

			//
			// base form
			//

			methodType: null,

			amountExcludingVAT: '',

			paymentName: '',
			paymentTaxNumber: '',
			paymentLegalAddress: '',

			paymentPhone: '',
			paymentEmail: '',

			errors: {},

			// END: base form

			paymentOptions: [
				{
					value: METHOD_TYPE_CARD,
					label: this.$t('billing.bank_card'),
				},
				{
					value: METHOD_TYPE_BANK_TRANSFER,
					label: this.$t('billing.bank_transfer'),
				},
			],
		};
	},


	async mounted() {

		// init forms
		this.paymentName = this.profileFullName;
		this.paymentEmail = this.profileEmail;
		this.paymentPhone = this.profilePhone;

		// get legal entity data for the current agency
		try {
			this.legalEntity = await legalEntityAPI.retrieve(this.currentAgencyId);
		}
		catch (err) {
			if (err.response && err.response.status === 404) {
				this.legalEntity = {};
			}
			else {
				throw err;
			}
		}

		if (this.currentLegalType === 'legal') {
			this.methodType = METHOD_TYPE_BANK_TRANSFER;
		} else if (this.currentLegalType === 'private') {
			this.methodType = METHOD_TYPE_CARD;
		} else {
			this.methodType = null; //unset
		}
	},


	watch: {

		/**
		 * Populate form data
		 */
		methodType(newValue, oldValue) {
			if (newValue === oldValue) {
				return;
			}

			if (newValue === METHOD_TYPE_CARD) {
				this.paymentName = this.profileFullName;
				this.paymentEmail = this.profileEmail;
				this.paymentPhone = this.profilePhone;
			}

			if (newValue === METHOD_TYPE_BANK_TRANSFER) {
				this.paymentEmail = this.legalEntity.email;
				this.paymentPhone = this.legalEntity.phone;
				this.paymentName = this.legalEntity.name;
				this.paymentTaxNumber = this.legalEntity.tax_number;
				this.paymentLegalAddress = this.legalEntity.legal_address;
			}

		},

	},


	computed: {
		...mapState('agency', {
			'currentAgencyId': 'id',
			'currentCurrency': 'currency',
			'currentLegalType': 'legal_type',
		}),

		...mapState('profile', {
			'profileEmail': 'email',
			'profilePhone': 'phone',
		}),

		...mapGetters('profile', {
			'profileFullName': 'fullName',
		}),


		nameLabel() {
			var name = this.$t('billing.payment_name_private_label');
			if (this.methodType === METHOD_TYPE_BANK_TRANSFER) {
				name = this.$t('billing.payment_name_legal_label');
			}

			return name;
		},


		methodTypeLabel() {
			var label = this.$t('billing.bank_card');
			if (this.methodType === METHOD_TYPE_BANK_TRANSFER) {
				label = this.$t('billing.bank_transfer');
			}

			return label;
		},


		/**
		 * TODO: minimum amount?
		 */
		isOkToPay() {
			let baseFieldsOK = this.methodType
				&& this.paymentName
				&& this.paymentEmail
				&& this.paymentPhone
				&& this.amountExcludingVAT > 0;

			// for `card` payment
			let additionalFieldsOK = true;

			// for `bank_transfer`
			if (this.methodType === METHOD_TYPE_BANK_TRANSFER) {
				additionalFieldsOK = this.paymentTaxNumber
					&& this.paymentLegalAddress;
			}

			return baseFieldsOK && additionalFieldsOK;
		},


		allInputsValid() {
			const inputs = this.$refs;
			for (const key in inputs) {
				if (inputs[key].error.is) {
					return false;
				}
			}
			return true;
		},

	},


	methods: {

		/**
		 * Add balance payment handler
		 */
		async paymentHandler(event) {
			let invoiceData;
			try {
				invoiceData = await getAddBalanceFormData({
					payment_method_id: this.paymentMethod.id,
					method: this.methodType,
					amount_excluding_vat: this.amountExcludingVAT,
					name: this.paymentName,
					tax_number: this.paymentTaxNumber,
					legal_address: this.paymentLegalAddress,
					phone: this.paymentPhone,
					email: this.paymentEmail,
				});
			}
			catch(error) {
				const fields = ['email', 'wrong_phone_number', 'wrong_vat_number'];

				this.errors = convertError(error.response, fields);
				let message = this.$t('failed_to_add_balance');
				if (this.errors) {
					const firstKey = Object.keys(this.errors)[0];
					message = this.errors[firstKey];
				}

				window.alert(message);
				return;
			}

			// redirect to a payment gateway
			if (invoiceData.payment_gateway_url) {
				location.href = invoiceData.payment_gateway_url;
			}
		},


		phoneValidate(value) {
			return phoneValidatorNumbersAndPlus(value);
		},

		emailValidate(value) {
			return emailValidator(value);
		},

		innValidate(value) {
			return innValidator(value);
		},


		close() {
			this.$router.push({
				name: 'billing',
				hash: '#' + this.$t('documents.invoices').toLowerCase(),
			});
		},

	},
};
</script>


<style lang="sass" scoped>
.buttons
	display: flex
	flex-direction: row
	justify-content: flex-end
	margin-top: $ui-grid__mgn-top * 2

	button
		margin-left: $ui-grid__mgn-x
</style>
