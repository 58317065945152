<template>
	<header 
		class="header-panel"
		:class="{ 'header-panel-ssp': platformSide === 'ssp'}"
	>
		<nav class="nav-menu">
			<router-link
				v-tippy="{
					placement: 'right',
					arrow: false,
				}"
				to="/"
				class="nav-menu__item nav-item nav-item_root logo"

				:aria-label="$t(`logo_caption_${ platformSide === 'ssp' ? 'ssp' : 'dsp' }`)"
				:content="$t(`logo_caption_${ platformSide === 'ssp' ? 'ssp' : 'dsp' }`)"
			>
				<logo class="logo" />
			</router-link>


			<template
				v-if="platformSide == 'dsp'"
			>
				<router-link
					v-tippy="{
						placement: 'right',
						arrow: false,
					}"
					class="nav-menu__item nav-item request_add"

					:to="{
						name: 'request-add',
					}"
					:content="$t('menu_request_add')"
					:title="$t('menu_request_add')"

				>
					<nice-icon-2 icon="plus-20" />
				</router-link>

				<span class="separator" />
			</template>

			<router-link
				v-for="page in pages"
				:key="page.name"

				v-tippy="{
					placement: 'right',
					arrow: false,
				}"
				:class="[
					'nav-menu__item',
					'nav-item',
					{ 'nav-item_dev': page.dev },
				]"

				:to="{ name: page.name }"
				:content="getPageName(page)"
				:aria-label="getPageName(page)"
			>
				<svg
					width="34"
					height="34"
					class="nav-menu__icon"
				>
					<use :xlink:href="`#header-panel__${ page.menu_icon }-icon`" />
				</svg>
			</router-link>
		</nav>
		<div 
			v-if="$uioverrides.help_phone || $uioverrides.help_email"
			class="hand" 
			@click="showHelpModal"
		>
			<nice-icon-2 icon="hand" />
		</div>
		<account-menu 
			class="header-panel__account-menu" 
			:class="{ 'account-menu-without-hand': !($uioverrides.help_phone || $uioverrides.help_email) }"
		/>


		<svg xmlns="http://www.w3.org/2000/svg" class="svg-def-container" hidden>
			<defs>

				<symbol id="header-panel__dashboard-icon" viewBox="0 0 34 34" >
					<path fill-rule="evenodd" clip-rule="evenodd" d="M9 10C9 8.89543 9.89543 8 11 8H15C16.1046 8 17 8.89543 17 10V14C17 15.1046 16.1046 16 15 16H11C9.89543 16 9 15.1046 9 14V10ZM11 10H15V14H11V10Z" fill="currentColor"/>
					<path fill-rule="evenodd" clip-rule="evenodd" d="M9 19C9 17.8954 9.89543 17 11 17H15C16.1046 17 17 17.8954 17 19V23C17 24.1046 16.1046 25 15 25H11C9.89543 25 9 24.1046 9 23V19ZM11 19H15V23H11V19Z" fill="currentColor"/>
					<path fill-rule="evenodd" clip-rule="evenodd" d="M20 8C18.8954 8 18 8.89543 18 10V14C18 15.1046 18.8954 16 20 16H24C25.1046 16 26 15.1046 26 14V10C26 8.89543 25.1046 8 24 8H20ZM24 10H20V14H24V10Z" fill="currentColor"/>
					<path fill-rule="evenodd" clip-rule="evenodd" d="M18 19C18 17.8954 18.8954 17 20 17H24C25.1046 17 26 17.8954 26 19V23C26 24.1046 25.1046 25 24 25H20C18.8954 25 18 24.1046 18 23V19ZM20 19H24V23H20V19Z" fill="currentColor"/>
				</symbol>

				<symbol id="header-panel__network-icon" viewBox="0 0 34 34" >
					<path d="M12 12.7324C12.5978 12.3866 13 11.7403 13 11C13 9.89543 12.1046 9 11 9C9.89543 9 9 9.89543 9 11C9 11.7403 9.4022 12.3866 10 12.7324V14.9142L12.0858 17L10 19.0858V21.2676C9.4022 21.6134 9 22.2597 9 23C9 24.1046 9.89543 25 11 25C12.1046 25 13 24.1046 13 23C13 22.2597 12.5978 21.6134 12 21.2676V19.9142L13.9142 18H16V21.2676C15.4022 21.6134 15 22.2597 15 23C15 24.1046 15.8954 25 17 25C18.1046 25 19 24.1046 19 23C19 22.2597 18.5978 21.6134 18 21.2676V18H20.0858L22 19.9142V21.2676C21.4022 21.6134 21 22.2597 21 23C21 24.1046 21.8954 25 23 25C24.1046 25 25 24.1046 25 23C25 22.2597 24.5978 21.6134 24 21.2676V19.0858L21.9142 17L24 14.9142V12.7324C24.5978 12.3866 25 11.7403 25 11C25 9.89543 24.1046 9 23 9C21.8954 9 21 9.89543 21 11C21 11.7403 21.4022 12.3866 22 12.7324V14.0858L20.0858 16H18V12.7324C18.5978 12.3866 19 11.7403 19 11C19 9.89543 18.1046 9 17 9C15.8954 9 15 9.89543 15 11C15 11.7403 15.4022 12.3866 16 12.7324V16H13.9142L12 14.0858V12.7324Z" fill="currentColor"/>
				</symbol>

				<symbol id="header-panel__requests-icon" viewBox="0 0 34 34" >
					<path fill-rule="evenodd" clip-rule="evenodd" d="M19.1331 10C19.1331 9.46957 18.9223 8.96086 18.5473 8.58579C18.1722 8.21071 17.6635 8 17.1331 8C16.6026 8 16.0939 8.21071 15.7188 8.58579C15.3438 8.96086 15.1331 9.46957 15.1331 10V10.2899C12.2416 11.1505 10.1332 13.829 10.1332 17V21L9.16963 22.4453C8.72659 23.1099 9.20299 24 10.0017 24H15.1331C15.1331 24.5304 15.3438 25.0391 15.7188 25.4142C16.0939 25.7893 16.6026 26 17.1331 26C17.6635 26 18.1722 25.7893 18.5473 25.4142C18.9223 25.0391 19.1331 24.5304 19.1331 24H24.2646C25.0633 24 25.5397 23.1099 25.0967 22.4453L24.1332 21V17C24.1332 13.8289 22.0246 11.1504 19.1331 10.2898V10ZM22.1332 21.6056L22.1331 22H12.1331L12.1332 21.6056V17C12.1332 14.2386 14.3717 12 17.1332 12C19.8946 12 22.1332 14.2386 22.1332 17V21.6056Z" fill="currentColor"/>
				</symbol>

				<symbol id="header-panel__campaigns-icon" viewBox="0 0 34 34" >
					<path fill-rule="evenodd" clip-rule="evenodd" d="M14.9231 22.7959L23.0462 18.1789C24.359 17.4327 24.359 15.5673 23.0462 14.8211L14.9231 10.2041C13.6103 9.45796 11.9692 10.3907 11.9692 11.883L11.9692 21.117C11.9692 22.6093 13.6103 23.542 14.9231 22.7959ZM24.0308 19.8578C26.6564 18.3654 26.6564 14.6346 24.0308 13.1422L15.9077 8.52524C13.282 7.03288 10 8.89832 10 11.883L10 21.117C10 24.1017 13.282 25.9671 15.9077 24.4748L24.0308 19.8578Z" fill="currentColor"/>
				</symbol>

				<symbol id="header-panel__gear-icon" width="34" height="34">
					<path fill="currentColor" stroke="currentColor" stroke-width=".5" d="M17 14a3 3 0 100 6 3 3 0 000-6zm0 5a2.01 2.01 0 01-2-2c0-1.1.9-2 2-2a2 2 0 012 2 2 2 0 01-2 2z"/>
					<path fill="currentColor" stroke="currentColor" stroke-width=".5" d="M27.87 16.2a2.57 2.57 0 00-2.46-1.7h-.2a.9.9 0 01-.65-1.53l.15-.15c.8-.8 1.02-2.01.53-2.93a2.5 2.5 0 00-4-.67l-.2.2a.89.89 0 01-1 .18.9.9 0 01-.55-.81v-.21c0-1.13-.7-2.14-1.7-2.46a2.53 2.53 0 00-3.3 2.38v.3a.9.9 0 01-.56.83.91.91 0 01-.96-.2l-.15-.15a2.57 2.57 0 00-2.94-.53 2.52 2.52 0 00-1.26 3.02c.12.37.33.71.6.99l.22.2c.25.26.32.64.17 1a.89.89 0 01-.81.54h-.21c-1.13 0-2.14.7-2.46 1.7a2.53 2.53 0 002.37 3.3h.3a.9.9 0 01.64 1.53l-.15.15a2.56 2.56 0 00-.53 2.93 2.5 2.5 0 004 .67l.2-.2a.89.89 0 011-.18c.33.13.55.46.55.81v.21c0 1.13.7 2.14 1.7 2.47a2.54 2.54 0 003.3-2.4v-.29a.9.9 0 011.52-.64l.15.15c.8.8 2.01 1.02 2.94.54a2.5 2.5 0 00.66-4.02l-.2-.2a.89.89 0 01-.18-1 .89.89 0 01.81-.55h.3a2.5 2.5 0 002.36-3.29v0zm-1.16 1.68a1.5 1.5 0 01-1.21.62h-.3a1.9 1.9 0 00-1.75 1.18c-.29.71-.13 1.52.41 2.06l.21.2a1.5 1.5 0 01-.42 2.43c-.54.28-1.28.13-1.77-.36l-.14-.15a1.89 1.89 0 00-2.08-.4 1.89 1.89 0 00-1.17 1.74v.3c0 .47-.23.93-.62 1.21-.39.28-.9.37-1.38.21-.59-.19-1-.8-1-1.5v-.22a1.9 1.9 0 00-3.24-1.35l-.2.21a1.5 1.5 0 01-2.43-.42c-.28-.54-.13-1.28.36-1.77l.15-.14a1.89 1.89 0 00.4-2.08 1.89 1.89 0 00-1.74-1.17H8.5a1.52 1.52 0 01-1.48-1.29c-.04-.24-.02-.48.06-.71.19-.57.8-.98 1.5-.98h.22a1.9 1.9 0 001.75-1.18c.29-.71.13-1.52-.41-2.05l-.21-.21a1.5 1.5 0 01-.42-1.3c.07-.47.38-.88.83-1.12.54-.29 1.28-.14 1.77.35l.14.15c.55.53 1.36.7 2.08.4A1.89 1.89 0 0015.5 8.8v-.3c0-.47.23-.93.62-1.21.4-.28.9-.37 1.38-.21.59.19 1 .8 1 1.5v.22a1.9 1.9 0 003.24 1.34l.2-.21c.34-.34.82-.5 1.3-.42.48.07.88.38 1.12.83.29.54.14 1.28-.35 1.77l-.15.14a1.89 1.89 0 00-.4 2.08 1.89 1.89 0 001.74 1.17h.21c.7 0 1.32.41 1.51 1 .16.49.08 1-.2 1.38v0z"/>
				</symbol>

				<symbol id="header-panel__person-icon" viewBox="0 0 34 34" >
					<path fill-rule="evenodd" clip-rule="evenodd" d="M11.9824 13C11.9824 10.2386 14.221 8 16.9824 8C19.7438 8 21.9824 10.2386 21.9824 13V14C21.9824 15.6728 21.1609 17.1538 19.8993 18.0614L21.4473 18.6634C23.6955 19.5377 25.3352 21.508 25.7865 23.8777L25.9647 24.8129L24 25.1871L23.8219 24.2519C23.4995 22.5593 22.3283 21.1519 20.7224 20.5274L18.7946 19.7777C17.6289 19.3244 16.3357 19.3244 15.1701 19.7777L13.2423 20.5274C11.6364 21.1519 10.4652 22.5593 10.1428 24.2519L9.96468 25.1871L8 24.8129L8.17814 23.8777C8.6295 21.508 10.2692 19.5377 12.5174 18.6634L14.0654 18.0614C12.8039 17.1537 11.9824 15.6728 11.9824 14V13ZM19.9824 13V14C19.9824 15.6569 18.6393 17 16.9824 17C15.3256 17 13.9824 15.6569 13.9824 14V13C13.9824 11.3431 15.3256 10 16.9824 10C18.6393 10 19.9824 11.3431 19.9824 13Z" fill="currentColor"/>
				</symbol>

				<symbol id="header-panel__money-icon" x="8" y="8" width="20" height="20" viewBox="0 0 20 20" fill="none">
					<ellipse cx="7" cy="4.5" rx="6" ry="2.5" stroke="currentColor" stroke-width="2"/>
					<path d="M13 9.00002C13 8.07466 11.7934 7.26673 10 6.83447" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
					<path d="M7 16C3.68629 16 1 14.8807 1 13.5C1 12.6822 1.94241 11.9561 3.39942 11.5" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
					<path d="M7 11.5C3.68629 11.5 1 10.3807 1 9C1 8.1822 1.94241 7.45611 3.39942 7" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
					<ellipse cx="13" cy="11.5" rx="6" ry="2.5" stroke="currentColor" stroke-width="2"/>
					<path
						d="M9.39942 14C7.94241 14.4561 7 15.1822 7 16C7 17.3807 9.68629 18.5 13 18.5C16.3137 18.5 19 17.3807 19 16C19 15.1822 18.0576 14.4561 16.6006 14"
						stroke="currentColor"
						stroke-width="2"
						stroke-linecap="round"
					/>
				</symbol>

				<symbol id="header-panel__warn-icon" viewBox="0 0 34 34" >
					<circle cx="17" cy="17" r="7.5" fill="none" stroke="currentColor" stroke-width="2"/>
					<line x1="17" y1="12" x2="17" y2="19" stroke="currentColor" stroke-width="2"/>
					<line x1="17" y1="20" x2="17" y2="22" stroke="currentColor" stroke-width="2"/>
				</symbol>

				<symbol id="header-panel__error-icon" viewBox="0 0 34 34" >
					<circle cx="17" cy="17" r="7.5" fill="none" stroke="currentColor" stroke-width="2"/>
					<line x1="20.5355" y1="13.4644" x2="13.4644" y2="20.5355" stroke="currentColor" stroke-width="2"/>
					<line x1="20.5356" y1="20.5355" x2="13.4645" y2="13.4644" stroke="currentColor" stroke-width="2"/>
				</symbol>

				<symbol id="header-panel__dev-icon" viewBox="0 0 34 34" >
					<rect x="12.6362" y="14.0454" width="2" height="10.6709" transform="rotate(-45 12.6362 14.0454)" fill="currentColor"/>
					<path fill-rule="evenodd" clip-rule="evenodd" d="M10.2844 11.7074C9.89868 12.1006 9.90354 12.7321 10.2959 13.1193L11.7101 14.5152C12.1031 14.9031 12.7363 14.899 13.1242 14.5059L14.5201 13.0917C14.9081 12.6987 14.9039 12.0655 14.5109 11.6776L13.0967 10.2817C12.7044 9.8945 12.073 9.89785 11.6848 10.2886L13.0988 11.6843C13.4906 12.071 13.4947 12.7021 13.108 13.0939C12.7213 13.4857 12.0902 13.4898 11.6984 13.1031L10.2844 11.7074Z" fill="currentColor"/>
					<path fill-rule="evenodd" clip-rule="evenodd" d="M23.01 23.005C23.791 22.224 23.791 20.9577 23.01 20.1766C22.2289 19.3956 20.9626 19.3956 20.1815 20.1766C19.4005 20.9577 19.4005 22.224 20.1815 23.005C20.9626 23.7861 22.2289 23.7861 23.01 23.005ZM22.3029 22.2979C22.6934 21.9074 22.6934 21.2742 22.3029 20.8837C21.9123 20.4932 21.2792 20.4932 20.8887 20.8837C20.4981 21.2742 20.4981 21.9074 20.8887 22.2979C21.2792 22.6885 21.9123 22.6885 22.3029 22.2979Z" fill="currentColor"/>
					<path fill-rule="evenodd" clip-rule="evenodd" d="M22.2868 10.2795C21.8935 9.89379 21.2621 9.89866 20.8748 10.291L19.479 11.7052C19.091 12.0982 19.0951 12.7314 19.4882 13.1194L20.9024 14.5152C21.2955 14.9032 21.9286 14.8991 22.3166 14.506L23.7124 13.0918C24.0996 12.6995 24.0963 12.0681 23.7056 11.6799L22.3099 13.0939C21.9231 13.4857 21.292 13.4898 20.9002 13.1031C20.5084 12.7164 20.5043 12.0853 20.891 11.6935L22.2868 10.2795Z" fill="currentColor"/>
					<path fill-rule="evenodd" clip-rule="evenodd" d="M10.9891 23.0051C11.7702 23.7861 13.0365 23.7861 13.8175 23.0051C14.5986 22.224 14.5986 20.9577 13.8175 20.1767C13.0365 19.3956 11.7702 19.3956 10.9891 20.1767C10.2081 20.9577 10.2081 22.224 10.9891 23.0051ZM11.6962 22.298C12.0867 22.6885 12.7199 22.6885 13.1104 22.298C13.501 21.9075 13.501 21.2743 13.1104 20.8838C12.7199 20.4932 12.0867 20.4932 11.6962 20.8838C11.3057 21.2743 11.3057 21.9075 11.6962 22.298Z" fill="currentColor"/>
					<path fill-rule="evenodd" clip-rule="evenodd" d="M16.2924 16.2874L12.4033 20.1765L13.8175 21.5907L17.7066 17.7016L16.2924 16.2874ZM19.1209 16.2874L21.2378 14.1704L19.8236 12.7562L17.7066 14.8732L19.1209 16.2874Z" fill="currentColor"/>
				</symbol>

			</defs>
		</svg>
	</header>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import AccountMenu from '@/components/account-menu.vue';
import Logo from '@/ui/logo.vue';

export default {
	name: 'HeaderPanel',


	components: {
		AccountMenu,
		Logo,
	},


	computed: {
		...mapState('app', {
			platformSide: 'side',
		}),
		...mapState('menu', {
			pages: 'pages',
		}),
	},  // END: computed


	methods: {
		...mapMutations('modals', ['setShowHelpModal']),

		getPageName(page) {
			return this.$t('page_caption_' + page.name) || page.name;
		},

		showHelpModal() {
			this.setShowHelpModal(true);
		},
	},  // END: methods
};
</script>

<style lang="sass" scoped>
$icon-color: #ababab
$icon-color__hover: #5f5f5f
$icon-color__active: #2dc3b7

.header-panel
	display: flex
	flex-direction: column
	align-items: center
	border-right: 1px solid #dddddd
	padding: 11px 0

	/deep/ .logo
		width: 34px
		height: 34px

.nav-menu
	display: flex
	flex-direction: column
	align-items: center
	width: 100%

.nav-item
	position: relative
	display: block
	margin-bottom: 14px

	color: $icon-color
	cursor: pointer
	text-decoration: none
	text-transform: uppercase
	transition: color .2s ease

	&:hover
		color: $icon-color__hover

	&.router-link-exact-active
		color: var(--main_color)
		cursor: default

	&.logo
		margin-bottom: 30px

	&.request_add
		margin-bottom: 30px

.separator
	width: calc(100% - #{12px * 2})
	height: 1px
	margin: 0 12px 26px
	background: $nice_color-gray_light_semi

.nav-item_dev::after
	position: absolute
	top: calc(100% - 15px)
	left: calc(100% - 15px)
	content: 'dev'
	display: inline-block
	font-size: 6px
	line-height: 1.6
	padding: 0 .3em
	color: #ffffff
	background-color: var(--main_color)
	border-radius: 3px

.header-panel__account-menu
	margin-top: 30px

.account-menu-without-hand
	margin-top: auto

.hand 
	margin-top: auto
	width: 18px
	height: 18px
	cursor: pointer
	color: var(--text_2_color)

.hand:hover
	color: black

.header-panel-ssp
	background: #eef2f4
</style>
