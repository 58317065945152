<template>
		<div
			class="data_block"
		>
				<!-- Audience -->
				<!-- TMP: for prod deploy
				<template
					v-if="instance.payment_model === PAYMENT_MODEL_CPM"
				>
					<h3>
						{{ $t('campaign.audience_label') | capitalize }}
						<nice-hint :hint="$t('campaign.audience_hint')" />
					</h3>
					<audience-cpm
						:interests.sync="instance.interests"
						:life-stages.sync="instance.life_stages"
						:intents.sync="instance.intents"
						:errors="{
							// interests: context.errors.interests,
							// life_stages: context.errors.life_stages,
							// intents: context.errors.intents,
						}"
					/>
				</template>
				-->
			<template
				v-if="paymentModel === PAYMENT_MODEL_CPV && additionalProps.audience"
			>
				<h3>
					{{ $t('campaign.audience_label') | capitalize }}
					<nice-hint :hint="$t('campaign.audience_hint')" />
				</h3>
				<div class="audience-cpv">
					<audience-cpv
						:age="age"
						:gender="gender"
						:errors="{
							// age: context.errors.age,
							// gender: context.errors.gender,
						}"
						@update:age="$emit('update:age', $event)"
						@update:gender="$emit('update:gender', $event)"
					/>
				</div>
			</template>

			<!-- Environment -->
			<div v-if="environmentList.length > 1 && additionalProps.environments">
				<h3>
					{{ $t('campaign.environment_label') | capitalize }}
					<nice-hint :hint="$t('campaign.environment_hint')"/>
				</h3>
				<environments-tree
					:environments="environments"
					:environment-list="environmentList"
					@update:environments="$emit('update:environments', $event)"

				/>
			</div>

			<!-- POI -->
			<!-- TODO: not usable currently - too laggy and no map markers
			<div v-if="poiList.length > 0">
				<h3>
					{{ $t('campaign.poi_label') | capitalize }}
					<nice-hint :hint="$t('campaign.poi_hint')"/>
				</h3>
				<pois-select
					:pois="pois"
					:poi-list="poiList"
					@update:pois="$emit('update:pois', $event)"
				/>
			</div>
			-->

			<!-- Broadcasting points -->
			<div v-if="resolutionList.length > 1 || broadcatsTypesList.length > 1">
				<h3>{{$t('campaign.broadcasting_points') | capitalize}}</h3>
				<broadcast-points
					:platforms="platforms"
					:resolutions="resolutions"
					:resolution-list="resolutionList"
					:orientations="orientations"
					:orientation-list="orientationList"
					:device-types="deviceTypes"
					:broadcast-types-list="broadcatsTypesList"
					:fields="additionalProps.broadcast_points.fields"
					:errors="{
						// resolutions: context.errors.resolutions,
						// orientations: context.errors.orientations,
						// device_types: context.errors.device_types,
					}"
					@update:resolutions="$emit('update:resolutions', $event)"
					@update:orientations="$emit('update:orientations', $event)"
					@update:device-types="$emit('update:device-types', $event)"
				/>
			</div>
		</div>
</template>


<script>
import _ from 'underscore';

import gisAPI from '@/api/gis';

import NiceHint from '@/ui/nice-hint';
import BroadcastPoints from './broadcast-points.vue';
import EnvironmentsTree from './environments-tree.vue';
import AudienceCpv from './audience-cpv.vue';
// import PoisSelect from './pois.vue';

import {
	PAYMENT_MODEL_AD_PLAYS,
	PAYMENT_MODEL_CPM,
	PAYMENT_MODEL_CPV,
} from '@/constants/payment_models';

const DEFAULT_AGE_RANGE = [0, 100];


export default {
	name: 'DeviceChoices',


	components: {
		BroadcastPoints,
		EnvironmentsTree,
		NiceHint,
		AudienceCpv,
		// PoisSelect,
	},


	props: {
		paymentModel: String,

		platforms: {
			type: [Array, String],
			default: 'all'
		},

		age: {
			type: Array,
			default: () => DEFAULT_AGE_RANGE
		},

		gender: {
			type: String,
			default: () => 'all'
		},

		environments: {
			type: Array,
			default: () => [],
		},

		/**
		 * Format: poi1,poi2,...;radius_in_meters
		 */
		pois: {
			type: String,
			default: () => '',
		},

		resolutions: {
			type: Array,
			default: () => [],
		},

		orientations: {
			type: Array,
			default: () => [],
		},

		deviceTypes: {
			type: Array,
			default: () => [],
		},

		additionalProps: {
			type: Object,
			default: () => ({
				broadcast_points: {
					fields: ['resolutions', 'orientations', 'broadcast_types']
				},
				environments: true,
				audience: true,
			}),
		},
	},


	data() {
		return {
			PAYMENT_MODEL_AD_PLAYS,
			PAYMENT_MODEL_CPM,
			PAYMENT_MODEL_CPV,

			environmentList: [],
			poiList: [],

			resolutionList: [],
			orientationList: [],
			broadcatsTypesList: [],
		};
	},


	async created() {
		// Currently API returns request instance with `age` field === [0, 100]
		// So age spoiler will always be extended
		// Waiting for api update
		this.isAgeSpoilerExpanded = !this.age || !_.isEqual(this.age, DEFAULT_AGE_RANGE);
		this.isGenderSpoilerExpanded = this.gender !== 'all';

		await Promise.all([
			this.$asyncSet(this, 'broadcatsTypesList', this.$store.dispatch('extra/getBroadcastTypes', {
				platforms: this.platforms || 'all',
				force: true
			})),
			this.$asyncSet(this, 'resolutionList', this.$store.dispatch('devices/getResolutions', {
				platforms: this.platforms || 'all'
			})),
			this.$asyncSet(this, 'orientationList', this.$store.dispatch('devices/getOrientations')),
			this.$asyncSet(this, 'environmentList', this.$store.dispatch('devices/getEnvironments', {
				platforms: this.platforms || 'all'
			})),
			this.$asyncSet(this, 'poiList', gisAPI.getPOIs()),
		]);

		this.isTreesLoaded = true;
	},

	watch: {
		resolutionList: {
			handler(newList) {
				// если resolution один, то он скрывается, поэтому сбрасываем фильтр, если он не выбран
				if (newList.length == 1 && !this.resolutions.includes(newList[0].value)) {
					this.$emit('update:resolutions', []);
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>


<style lang="sass" scoped >
$column_indent: 45px
$row--indent: 20px
$row--width: 330px

.data_block
	display: flex
	flex-direction: column
	flex-wrap: nowrap
	justify-content: flex-start
	align-items: stretch

	> *

		&:first-child
			margin-top: 0

		&:last-child
			margin-bottom: 0

	h3
		margin-top: 0
		margin-bottom: $row--indent
		font-weight: normal
		font-size: $fsz__new__section-title

	.nice-spoiler
		box-sizing: border-box
		width: 100%
		max-width: 380px
		margin: 0

		&.ni_slider--container
			margin-top: $row--indent / 2
			margin-bottom: $row--indent / 2
	
	.audience-cpv
		margin-bottom: $cmpnt-rc__content-margin-default * 1.3
</style>
