
export const DEVICE_DEPLOYED = 'deployed';
export const DEVICE_NOT_DEPLOYED = 'not_deployed';
export const DEVICE_FILTERED = 'filtered';
export const DEVICE_BLACK_LIST = 'black';
export const DEVICE_WHITE_LIST = 'white';


// UPTIME MODE 
export const DEVICE_DEPLOYED_BAD = 'deployed_bad';
export const DEVICE_NOT_DEPLOYED_BAD = 'not_deployed_bad';
export const DEVICE_DEPLOYED_AVERAGE = 'deployed_average';
export const DEVICE_NOT_DEPLOYED_AVERAGE = 'not_deployed_average';
export const DEVICE_DEPLOYED_GOOD = 'deployed_good';
export const DEVICE_NOT_DEPLOYED_GOOD = 'not_deployed_good';

export const DEVICE_STATE_UPTIME_TEXT_COLOR_MAP = {
	[DEVICE_DEPLOYED_BAD]: '#ed4543',
	[DEVICE_NOT_DEPLOYED_BAD]: '#e6761b',
	[DEVICE_DEPLOYED_AVERAGE]: '#177bc9',
	[DEVICE_NOT_DEPLOYED_AVERAGE]: '#82cdff',
	[DEVICE_DEPLOYED_GOOD]: '#1bad03',
	[DEVICE_NOT_DEPLOYED_GOOD]: '#56db40',
};


