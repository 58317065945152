<template>
	<main class="page-auth">
		<section class="auth-container">
			<form
					v-if="urlToken"
					class="auth-form"
					@submit.prevent="changePasswordRequest"
			>
				<h2>
					{{ $t('reset_password.enter_new_password') }}
				</h2>
				<nice-input
						v-model="password"
						:type="showPassword ? 'text' : 'password'"
						:placeholder="$t('common.type_password')"
						:label="$t('common.password') | capitalize"
						class="password"

						autocomplete="current-password password"
				>
					<nice-button-2
						class="password-see-button"
						:class="{ 'show-password': showPassword }"
						icon="see-24"
						@click="togglePassword"
					/>
				</nice-input>
				<nice-input
						v-model="passwordConfirmation"
						:type="showPassword ? 'text' : 'password'"
						:placeholder="$t('common.type_password')"
						:label="$t('common.confirm_password')"
						class="password"

						autocomplete="current-password password"
				>
					<nice-button-2
						class="password-see-button"
						:class="{ 'show-password': showPassword }"
						icon="see-24"
						@click="togglePassword"
					/>
				</nice-input>
				<nice-label
						v-if="password.length > 0 && notValidPassword"
						class="label-detail long-text"
						:label="$t('reset_password.password_not_valid', {minLength: getMinPasswordLength})"
						:error="true"
				/>
				<nice-label
						v-else-if="passwordNotTheSame"
						class="label-detail"
						:label="$t('reset_password.passwords_not_the_same')"
						:error="true"
				/>
				<nice-label
						v-else-if="error"
						class="label-detail long-text"
						:label="error"
						:error="true"
				/>

				<div class="buttons-container">
					<nice-button-2
							type="submit"
							icon="arrow-14"
							:icon-state="-90"
							icon-placement="end"
							icon-painted
							:disabled="password.length < 1 || passwordConfirmation !== password || notValidPassword"
							class="submit_button"
					>{{ $t('common.send') | capitalize }}
					</nice-button-2>
				</div>
				<div class="buttons-container container-back">
					<button type="button"
									class="login-button"
									@click="$router.push({name: 'login'})"
					>
						{{ $t('common.back') }}
					</button>
				</div>
			</form>
		</section>
	</main>
</template>

<script>
import {mapActions} from 'vuex';

import NiceInput from '@/ui/nice-input.vue';

export default {
	name: 'ChangePassword',

	props: {
		urlToken: {
			type: String,
			required: true
		}
	},

	components: {
		NiceInput,
	},


	data() {
		return {
			password: '',
			passwordConfirmation: '',
			showPassword: false,

			error: null
		};
	},

	computed: {
		notValidPassword() {
			return this.password.length < this.getMinPasswordLength;
		},

		passwordNotTheSame() {
			return this.password !== this.passwordConfirmation;
		},

		getMinPasswordLength() {
			return process.env.VUE_APP_MIN_PASSWORD_LENGTH;
		}
	},

	methods: {
		...mapActions('profile', ['changePassword']),

		togglePassword() {
			this.showPassword = !this.showPassword;
		},

		async changePasswordRequest() {
			this.error = null;

			if (!this.passwordNotTheSame && !this.notValidPassword) {
				try {
					await this.changePassword({
						password: this.password,
						token: this.urlToken
					});

					await this.$router.push({
						name: 'choose-agency'
					});
				} catch (error) {
					this.error = this.getError(error.response);
				}
			}
		},

		getError(error) {
			if (error && error.status === 400) {
				if (error.data[0]) {
					return error.data[0];
				}
			}

			return this.$t('errors.unexpected_error');
		}
	}
};
</script>

<style lang="sass" scoped>
main.page-auth
	display: flex
	align-items: center
	justify-content: center

	min-height: 100vh

	h2
		margin: 0 0 10px

.auth-container
	margin: 0 auto
	width: 100%
	max-width: 450px
	padding: 60px
	box-sizing: border-box

.auth-form
	display: flex
	flex-direction: column
	flex-wrap: nowrap
	justify-content: flex-start
	align-items: stretch

	> *
		margin-top: $ui-grid__mgn-top

.buttons-container
	margin-top: 30px
	display: flex
	flex-direction: row
	justify-content: space-between

.register-button
	align-self: flex-start

.submit_button
	align-self: flex-end

.label-detail
	font-size: 90%

.login-button
	margin-top: 5px
	padding: 0
	background: none
	border: none
	text-align: left
	color: var(--text_2_color)
	cursor: pointer

.container-back
	margin-top: 0

.long-text::v-deep .ni_label--label
	overflow: visible

::v-deep .password input
	padding-right: 30px

.password-see-button
	position: absolute
	right: 0
	border-radius: 0
	min-height: 30px

.show-password
	opacity: 0.75

</style>
