<template>
	<section
		class="review-tab layout-center805"
	>
		<!-- the map -->
		<map-device
			:source-data="mapSource"
			:selected-areas-data.sync="tempInstance.areas"
			enable-geocoder
			:search-query="searchQuery"
			class="map-device layout_block-full_width"
			:campaign-id="instance.id"
			:without-control="!isEditModeOn"

			@update="updateMapbox"
			@searchListRequest="searchlistRequestHandler"
		/>

		<main class="wrapper cl-container">

			<div class="order-details cl-xs8">

				<div class="edit-button">
					<nice-button-2
						v-if="appSide === 'dsp' && !isEditModeOn && !hasRestrictedStatus(instance)"
						class="inline-edit-button"
						icon="pencil-13"
						bordered
						@click="editModeOn"
					>
						{{ $t('campaign.campaign_edit') | capitalize }}
					</nice-button-2>

					<div
						v-if="isEditModeOn"
						class="control-buttons"
					>
						<nice-button-2
							class="inline-edit-button_control"
							filled
							bordered
							@click="applyChanges"
						>
							{{ $t('campaign.save') | capitalize}}
						</nice-button-2>

						<nice-button-2
							class="inline-edit-button_control"
							bordered
							@click="cancelChanges"
						>
							{{ $t('campaign.cancel') | capitalize}}
						</nice-button-2>
					</div>
				</div>

				<!-- platform -->
				<h3 class="order-section-title">{{ $t('campaign.platform_label') | capitalize }}</h3>
				<p>{{ platformList.map(p => p.name).join(', ') || '—' }}</p>

				<hr class="line" />

				<select-areas
					v-if="isEditModeOn"
					v-model="tempInstance.areas"
					:search-list-request="searchListRequest"
					:search-query.sync="searchQuery"
				/>

				<device-choices
					v-if="isEditModeOn"
					:key="`devicechoices-${platformList.map(p => p.id).join('-')}`"
					:payment-model="tempInstance.payment_model"
					:platforms="instance.platforms"
					:age.sync="tempInstance.age"
					:gender.sync="tempInstance.gender"
					:environments.sync="tempInstance.environments"
					:resolutions.sync="tempInstance.resolutions"
					:orientations.sync="tempInstance.orientations"
					:device-types.sync="tempInstance.device_types"
					:additional-props="additionalProps"
				/>

				<template v-else>
					<!-- audience -->
					<template v-if="instance.payment_model == PAYMENT_MODEL_CPV">
						<h3 class="order-section-title">{{ $t('campaign.audience_label') | capitalize }}</h3>

						<!-- age -->
						<div class="order-subsection">
							<h4 class="order-section-subtitle">{{ $t('campaign.age_label') | capitalize }}</h4>
							<div v-if="instance.age[0] != 0 && instance.age[1] != 100">
								<div class="detail detail-huge cl-xs8 cl-pad time_range-label"  >
									<nice-label class="label" :label="$t('campaign.age_range') | capitalize" />
									<span class="value">{{ instance.age[0] }}&nbsp;&mdash; {{ instance.age[1] }}</span>
								</div>
								<nice-range
									v-model="instance.age"
									:axis="{ start: 18, end: 64, step: 1 }"
									disabled
									demo
									class="range-preview"
								>
									<template #viz="options">
										<nice-range-viz v-bind="options" />
									</template>
									<nice-range-legend-item placement="start" label="18—24" />
									<nice-range-legend-item placement="value" position="31" label="25—34" />
									<nice-range-legend-item placement="center" label="35—44" />
									<nice-range-legend-item placement="value" position="52" label="45—54" />
									<nice-range-legend-item placement="end" label="55—64" />
								</nice-range>
							</div>
							<p v-else class="order-section-value" >{{ $t('campaign.all') | capitalize }}</p>
						</div>


						<!-- gender -->
						<div class="order-subsection">
							<h4 class="order-section-subtitle">{{ $t('campaign.gender_label') | capitalize }}</h4>
							<p class="order-section-value" >{{ $t(`campaign.${instance.gender}`) | capitalize }}</p>
						</div>

						<!-- TODO: TMP: -->
						<template v-if="1 == 0">
							<!-- interests -->
							<div
								v-if="interestsAndActivitiesList"
								class="order-subsection"
							>
								<h4 class="order-section-subtitle">{{ $t('campaign.interests') | capitalize }}</h4>
								<nice-tree-demo
									v-if="instance.interests && instance.interests.length"
									v-model="instance.interests"
									:tree="interestsAndActivitiesList"
								/>
								<p v-else class="order-section-value" >{{ $t('campaign.all') | capitalize }}</p>
							</div>

							<!-- life stage -->
							<div
								v-if="lifeStageList"
								class="order-subsection"
							>
								<h4 class="order-section-subtitle">{{ $t('campaign.life_stage_label') | capitalize }}</h4>
								<nice-tree-demo
									v-if="instance.life_stages && instance.life_stages.length"
									v-model="instance.life_stages"
									:tree="lifeStageList"
								/>
								<p v-else class="order-section-value" >{{ $t('campaign.all') | capitalize }}</p>
							</div>

							<!-- itent -->
							<div
								v-if="intentList"
								class="order-subsection"
							>
								<h4 class="order-section-subtitle">{{ $t('campaign.intent_label') | capitalize }}</h4>
								<nice-tree-demo
									v-if="instance.intents && instance.intents.length"
									v-model="instance.intents"
									:tree="intentList"
								/>
								<p v-else class="order-section-value" >{{ $t('campaign.all') | capitalize }}</p>
							</div>
						</template><!-- end: TMP -->

					<hr class="line" />

					</template><!-- end: audience -->

					<!-- broadcasting points -->
					<h3 class="order-section-title">{{ $t('campaign.broadcasting_points') | capitalize }}</h3>


					<!-- environments -->
					<!-- TODO: TMP: <div
						v-if="environmentList"
						class="order-subsection"
					>
						<h4 class="order-section-subtitle">{{ $t('campaign.environment_label') | capitalize }}</h4>
						<nice-tree-demo
							v-if="instance.environments && instance.environments.length"
							v-model="instance.environments"
							:tree="environmentList"
						/>
						<p v-else class="order-section-value" >{{ $t('campaign.all') | capitalize }}</p>
					</div> -->

					<!-- resolution -->
					<div
						v-if="resolutionList"
						class="order-subsection"
					>
						<h4 class="order-section-subtitle">{{ $t('campaign.resolution_label') | capitalize }}</h4>
						<nice-tree-demo
							v-if="instance.resolutions && instance.resolutions.length"
							v-model="instance.resolutions"
							:tree="resolutionList"
						/>
						<p v-else class="order-section-value" >{{ $t('campaign.all') | capitalize }}</p>
					</div>


					<!-- orientation -->
					<!-- TMP: <div
						v-if="orientationList"
						class="order-subsection"
					>
						<h4 class="order-section-subtitle">{{ $t('campaign.orientation_label') | capitalize }}</h4>
						<nice-tree-demo
							v-if="instance.orientations && instance.orientations.length"
							v-model="instance.orientations"
							:tree="orientationList"
						/>
						<p v-else class="order-section-value" >{{ $t('campaign.all') | capitalize }}</p>
					</div> -->

					<!-- types -->
					<!-- TMP: <div
						v-if="broadcastTypesTree"
						class="order-subsection"
					>
						<h4 class="order-section-subtitle">{{ $t('campaign.type_label') | capitalize }}</h4>
						<nice-tree-demo
							v-if="instance.orientations && instance.orientations.length"
							v-model="instance.device_types"
							:tree="broadcastTypesTree"
						/>
						<p v-else class="order-section-value" >{{ $t('campaign.all') | capitalize }}</p>
					</div> -->
				</template>

				<hr class="line" />

				<!-- schedule -->
				<span class="value">
					<h3 class="order-section-title">{{ $t('campaign.schedule') | capitalize }}</h3>
				</span>
				<template v-if="isEditModeOn && appSide === 'dsp'" >
					<schedule
							:min-date="instance.start_date"
							:time-period.sync="tempInstance.time_period"
							:time-range.sync="tempInstance.time_range"
							:schedule.sync="tempInstance.schedule"

							:errors="{
								// time_period: {
								// 	start: context.errors.start_date,
								// 	end: context.errors.end_date,
								// },
								// schedule: context.errors.schedule,
							}"
					/>
				</template>
				<!-- time period? it was on a previous screen -->
				<template v-else>
				<div
					class="order-subsection details cl-container cl-pad-no_sides"
				>
					<div class="detail detail-huge cl-xs4 cl-pad" >
						<nice-label class="label" :label="$t('campaign.time_period') | capitalize" />
						<span class="value">
							<span>{{ timePeriod || '—' }}</span>
						</span>
					</div>

					<div class="detail detail-huge cl-xs4 cl-pad" >
						<nice-label class="label" :label="$t('campaign.ad_flight') | capitalize" />
						<span class="value">
							<span>{{ adFlight || '—' }}</span>
							<span class="units">{{ $tc('campaign.days_won', adFlight) }}</span>
						</span>
					</div>

					<div class="detail detail-huge cl-xs8 cl-pad time_range-label" >
						<nice-label class="label" :label="$t('campaign.time_range') | capitalize" />
						<span class="value">{{ timeRangeHumanize[0] }}&nbsp;&mdash; {{ timeRangeHumanize[1] }}</span>
					</div>

					<div class="detail detail-huge cl-xs6 cl-pad" >
						<nice-range
							v-model="instance.time_range"
							:axis="timeRangeAxis"
							disabled
							demo
						>
							<!-- class="range-preview" -->
							<template #viz="props" >
								<nice-range-viz v-bind="props" />
							</template>

							<nice-range-legend-item
								placement="start"
								label="00:00"
							/>

							<!-- 15*3*60 - shift on axis -->
							<nice-range-legend-item
								placement="value"
								:position="24*60*60/4 + 30*2*60"
								label="06:00"
							/>
							<nice-range-legend-item
								placement="center"
								label="12:00"
							/>
							<!-- 15*3*60 - shift on axis -->
							<nice-range-legend-item
								placement="value"
								:position="24*60*60/4*3 - 30*2*60"
								label="18:00"
							/>
							<nice-range-legend-item
								placement="end"
								label="23:59"
							/>
						</nice-range>
					</div>

					<div class="detail detail-huge cl-xs12 cl-pad" >
						<nice-label class="label" :label="$t('campaign.week_rule') | capitalize" />
						<span class="value">{{ weekRule || '—' }}</span>
					</div>
				</div>

				</template>
				<hr class="line" />

				<div
					class="order-subsection details cl-container cl-pad-no_sides"
				>
				<!--
					TMP
					<div
						v-if="isEditModeOn && appSide === 'dsp'"
						class="detail detail-huge cl-xs4 cl-pad"
					>
						<nice-select
							id="campaign-payment-model-select"
							v-model="tempInstance.payment_model"
							:label="$t('campaign.payment_model') | capitalize"
							:options="paymentModels"
							required
						/>
					</div>
				-->

					<div class="detail detail-huge cl-xs4 cl-pad" >
						<nice-label class="label" :label="$t('campaign.payment_model') | capitalize" />
						<span class="value">
							<span>{{ $t(`payment_models.${currentPaymentModel.value}`) || '—' }}</span>
						</span>
					</div>

					<div
						v-if="isEditModeOn && appSide === 'dsp'"
						class="detail detail-huge cl-xs4 cl-pad"
					>
						<nice-input
							v-model.number="tempInstance.ad_duration"
							:label="$t('campaign.message_duration') | capitalize"
							type="number"
							min="1"
							max="300"
							step="1"
							required
						/>
					</div>

					<div v-else class="detail detail-huge cl-xs4 cl-pad" >
						<nice-label class="label" :label="$t('campaign.message_duration') | capitalize" />
						<span class="value">
							<span>{{ instance.ad_duration || '—' }}</span>
							<span class="units">{{ $tc('campaign.seconds_won', instance.ad_duration) }}</span>
						</span>
					</div>

					<div
						v-if="isEditModeOn && appSide === 'dsp'"
						class="frequency-limit detail detail-huge cl-xs4 cl-pad"
					>
						<nice-input
							v-model.number="tempInstance.ad_rate_per_hour"
							:label="$t('campaign.frequency_limit') | capitalize"
							type="number"
							min="1"
							max="720"
							step="1"
							:hint="$t('campaign.per_hour_per_device')"
							required
						/>
					</div>

					<div v-else class="frequency-limit detail detail-huge cl-xs4 cl-pad" >
						<nice-label class="label" :label="$t('campaign.frequency_limit') | capitalize" />
						<span class="value">
							<span>{{ instance.ad_rate_per_hour || '—' }}</span>
							<span
								class="units"
								v-html="$t('campaign.per_hour')"
							/>
						</span>
					</div>
				</div>

				<hr class="line" />

				<!-- budget -->
				<h3 class="order-section-title">{{ $t('campaign.budget') | capitalize }}</h3>
				<!-- time period? it was on a previous screen -->
				<div
					class="order-subsection details"
				>
					<div class="detail detail-huge" >
						<template v-if="isEditModeOn && appSide === 'dsp'" >
							<nice-input
								v-model="tempInstance.budget"
								:units="$options.filters.formatCurrency(instance.currency)"
								class="inline-edit-field inline-edit-field_huge"
								type="number"
								step="1"
								:label="$t('campaign.budget') | capitalize"
							/>
						</template>
						<template v-else>
							<nice-label class="label" :label="$t('campaign.budget') | capitalize" />
							<span class="value">
								<span>{{ $options.filters.formatNumber(instance.budget, decimal=2, separator='') || '—' }}</span>
								<span class="units">{{ instance.currency | formatCurrency }}</span>
							</span>
						</template>
					</div>


					<div class="detail detail-huge" >
						<template v-if="isEditModeOn && appSide === 'dsp'" >
							<div>
								<nice-input
									v-model="tempInstance.max_bid"
									:units="$options.filters.formatCurrency(instance.currency)"
									class="inline-edit-field inline-edit-field_huge"
									type="number"
									step="0.0001"
									:label="$t('campaign.max_bid') | capitalize"
								/>
							</div>
						</template>
						<template v-else >
							<nice-label class="label" :label="$t('campaign.max_bid') | capitalize" />
							<span class="value">
								<span>{{ $options.filters.formatNumber(instance.max_bid, decimal=2, separator='') || '—' }}</span>
								<span class="units">{{ instance.currency | formatCurrency }}</span>
							</span>
						</template>
						<span class="extra">
							{{$t(`payment_models.${currentPaymentModel.value}`)}}
							{{$t('campaign.max_bid_extra_detail',{message_duration:$tc('campaign.seconds', instance.ad_duration)})}}
						</span>
					</div>
				</div>

				<div class="edit-button">
					<nice-button-2
						v-if="appSide === 'dsp' && !isEditModeOn && !hasRestrictedStatus(instance)"
						class="inline-edit-button"
						icon="pencil-13"
						bordered
						@click="editModeOn"
					>
						{{ $t('campaign.campaign_edit') | capitalize }}
					</nice-button-2>

					<div
						v-if="isEditModeOn"
						class="control-buttons"
					>
						<nice-button-2
							class="inline-edit-button_control"
							filled
							bordered
							@click="applyChanges"
						>
							{{ $t('campaign.save') | capitalize}}
						</nice-button-2>

						<nice-button-2
							class="inline-edit-button_control"
							bordered
							@click="cancelChanges"
						>
							{{ $t('campaign.cancel') | capitalize}}
						</nice-button-2>
					</div>
				</div>
			</div>


			<aside class="aside cl-xs4">
				<device-card-list-summary
					v-if="stats"
					:paymentModel="currentPaymentModel.value"
					:plays="stats.plays"
					:impressions="stats.impressions"
					:inventory="stats.inventory"
					:specs="stats.specs"
					:showAdditionalValue="true"
					:timePeriod="{
						start: instance.start_date,
						end: instance.end_date,
					}"
					:playsPerDay="stats.plays_per_day"
					:devicesUptime="stats.devices_uptime"

					class="summary"
				/>

				<div class="aside__button-wrapper">
					<nice-button-2
						v-if="appSide === 'dsp' && isEditModeOn"
						v-tippy
						:content="$t('campaign.inventory_modal_hint')"
						filled
						icon-placement="end"
						class="aside__modal-button"
						@click="isInventoryModalShowing = true"
					>
						{{ $t('campaign.open_inventory_modal') }}
					</nice-button-2>
				</div>

			</aside>

			<inventory-details-modal
				v-if="isInventoryModalShowing"
				class="inventory-modal layout_block-full_width"
				:instance="instance"
				:current-selection="currentSelection"

				@update-required="updateStats"
				@close="isInventoryModalShowing = false"
			/>

		</main>
	</section>
</template>


<script>
import apiDsp from '@/api/dsp/device';
import apiSsp from '@/api/ssp/device';
import { request, getBaseApiUrl } from '@/api/request';

import moment from 'moment';
import { debounce } from 'underscore';
import { mapState, mapActions, mapGetters } from 'vuex';

// import NiceHint from '@/ui/nice-hint';
import DeviceCardListSummary from '@/components/device/card-list-summary';
import MapDevice from '@/components/map-device';
import SelectAreas from '@/components/device/select-areas';
import NiceInput from '@/ui/nice-input';
import NiceRange from '@/ui/nice-range';
import { getDevicesFilter, objectToQuery, deepCopy } from '@/utilites';
import { convertError } from '@/ui/utils/errors';
import { NiceRangeLegendItem, NiceRangeViz } from '@/ui/nice-range';
import { hasRestrictedStatus } from '@/constants/audit-status-codes';
import NiceTreeDemo from '@/ui/nice-tree-check/nice-tree-demo';
// import NiceSelect from '@/ui/nice-select';
import DeviceChoices from '../create/device-choices';
import InventoryDetailsModal from '../create/inventory-details-modal';

import Schedule from '../create/schedule';


import {
	PAYMENT_MODEL_CPV,
} from '@/constants/payment_models';


const TIME_RANGE_AXIS_STEP = 30;

const WEEK_DAYS_ORDER = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

// milliseconds. 1 second is too short?
const DEBOUNCE_TIMEOUT = 3000;

function formatDate(date) {
	return moment(date).format('DD MMM`YY');
}


export default {
	name: 'TabOrder',


	components: {
		// NiceHint,
		DeviceCardListSummary,
		MapDevice,
		NiceInput,
		NiceRange,
		NiceRangeLegendItem,
		NiceRangeViz,
		NiceTreeDemo,
		Schedule,
		// NiceSelect,
		DeviceChoices,
		InventoryDetailsModal,
		SelectAreas,
	},


	props: {
		// campaign
		instance: {
			type: Object,
			required: true,
		},

		resource: {
			type: String,
			default: 'request',
			validator: resource => [
				'request',
				'campaign',
			].includes(resource),
		},
	},


	data() {
		let timeRangeAxis = [];
		for (let i=0 ; i<24*60/TIME_RANGE_AXIS_STEP-1 ; i++) {
			timeRangeAxis.push(i*60*TIME_RANGE_AXIS_STEP);
		}
		timeRangeAxis.push(24*60*60-1);

		const {
			max_bid,
			payment_model,
			budget,
			ad_duration,
			ad_rate_per_hour,
			start_date,
			end_date,
			time_range,
			schedule,
			age,
			gender,
			environments,
			resolutions,
			orientations,
			device_types,
			areas,
		} = this.instance;

		const innerInstance = {
			max_bid,
			payment_model,
			budget,
			ad_duration,
			ad_rate_per_hour,
			time_period: {
				start: start_date,
				end: end_date,
			},
			time_range,
			schedule,
			age,
			gender,
			environments,
			resolutions,
			orientations,
			device_types,
			areas,
		};

		const tempInstance = { ...innerInstance };



		return {
			PAYMENT_MODEL_CPV,

			isInventoryModalShowing: false,
			environmentList: null,
			interestsAndActivitiesList: null,
			lifeStageList: null,
			intentList: null,
			resolutionList: null,
			orientationList: null,
			timeRangeAxis: timeRangeAxis,

			isEditModeOn: false,

			mapSource: {
				'type': 'FeatureCollection',
				'features': [],
			},

			searchQuery: '',
			searchListRequest: [],

			innerInstance: Object.freeze(deepCopy(innerInstance)),
			tempInstance: deepCopy(tempInstance),
			additionalProps: {
				broadcast_points: {
					fields: [/*'resolutions', */'orientations', 'broadcast_types']
				},
				environments: true,
				audience: true,
			},

			stats: null,

			currentSelection: {
				// depends on payment_model
				'plays': {
					'value': 0,
					'total': 0,
				},
				'impressions': {
					'value': 0,
					'total': 0,
				},
				'inventory': {
					'value': 0,
					'total': 0,
					'excel_url': '',
				},
				'price': {
					'min': 0,
					'max': 0,
					'avg': 0,
				},
				// [{type: 'monitor', resolution: '1920x1080', count: 21,}, ...]
				'specs': [],

				'total_max_budget': 0,
			},
		};
	},


	computed: {
		...mapState('app', {
			appSide: 'side',
		}),
		...mapState('extra', [
			'paymentModels',
			'broadcastTypes',
		]),
		...mapGetters('extra', [
			'getPaymentModelByValue',
			'getBroadcastTypeByValue',
		]),

		instanceJson() {
			return JSON.stringify(this.instance);
		},

		// get platforms
		platformList() {
			if (!this.instance.platforms || this.instance.platforms.length == 0) {
				return [];
			}

			const networks = this.appSide === 'ssp'
				? this.$store.state.app.sspNetworks
				: this.$store.state.app.dspNetworks;

			if (!networks || !networks.length) {
				return [];
			}

			return networks.filter(network => this.instance.platforms.indexOf(network.id) !== -1);
		},

		// Broadcast points types tree
		broadcastTypesTree() {
			if (!this.broadcastTypes) return null;
			return [
				{ value: 'all', label: 'All', parent: null, },
				...this.broadcastTypes.map(type => ({ parent: 'all', ...type })),
			];
		},

		// time period
		timePeriod() {
			let start = this.instance.start_date;
			let end = this.instance.end_date;

			return `${ start ? formatDate(start) : '⋯' } — ${ end ? formatDate(end) : '⋯' }`;
		},

		// ad flight
		adFlight() {
			let start = this.instance.start_date;
			let end = this.instance.end_date;
			let diff = moment(end).diff(moment(start), 'days');

			if (isNaN(diff)) {
				return 0;
			}

			// moment.diff works like this `20191001-20191002 == 1`. we need 2
			return diff + 1;
		},

		currentPaymentModel() {
			return this.getPaymentModelByValue(this.instance.payment_model);
		},

		/**
		 * `schedule` is an object: {
		 *   fri: true​
		 *   mon: true
		 *   sat: true
		 *   sun: true
		 *   thu: true
		 *   tue: true
		 *   wed: true
		 * }
		 *
		 * We need to make the right order
		 */
		weekRule() {
			let days = WEEK_DAYS_ORDER.filter(day => this.instance.schedule[day]);

			if (!days.length) {
				return this.$options.filters.capitalize(this.$t('campaign.all'));
			}

			return days.map(day => this.$options.filters.capitalize(this.$t(day))).join(', ');
		},

		devicesFilter() {
			const inst = {
				...this.instance,
				...this.tempInstance,
			};
			// we need only deployed devices here
			let filter = getDevicesFilter(inst, true);
			return objectToQuery(filter);
		},

		timeRangeHumanize() {
			return [
				moment.utc(this.instance.time_range[0]*1000).format('HH:mm'),
				moment.utc(this.instance.time_range[1]*1000).format('HH:mm')
			];
		},
	},

	methods: {
		...mapActions('devices', ['getStats']),
		...mapActions('campaign', {
			'updateCampaign': 'change',
		}),

		formatDate,

		hasRestrictedStatus,

		setMapSource: debounce( async function setMapSource() {
			const apiPath = (this.appSide == 'ssp' ? apiSsp : apiDsp).getPrefix().slice(0,-1);

			let filter = this.devicesFilter;
			// HACK: to show all devices if platform is not selected
			if (this.devicesFilter.indexOf('platforms') == -1) {
				filter += '&platforms=all';
			}

			const url = `${getBaseApiUrl()}${apiPath}.geojson${filter}`;

			const result = await request(url);

			this.$set(this, 'mapSource', result);
		}, DEBOUNCE_TIMEOUT),

		updateStats: debounce( async function updateStats() {
			const inst = {
				...this.instance,
				...this.tempInstance,
			};

			// only deployed devices
			let filter = objectToQuery(getDevicesFilter(inst, true));
			const response = await this.getStats({
				filter,
			});

			this.$set(this, 'currentSelection', response);
			this.$set(this, 'stats', response);
		}, DEBOUNCE_TIMEOUT),

		updateMapAndStats() {
			this.setMapSource();
			this.updateStats();
		},

		searchlistRequestHandler(e) {
			this.searchListRequest = e;
		},

		editModeOn() {
			this.isEditModeOn = true;
		},

		async applyChanges() {
			this.$set(this, 'innerInstance', Object.freeze(deepCopy(this.tempInstance)));

			const data = {
				...this.tempInstance,
				start_date: this.tempInstance.time_period.start,
				end_date: this.tempInstance.time_period.end,
			};

			delete data.time_period;

			try {
				await this.updateCampaign({
					id: this.instance.id,
					data: data,
				});
			} catch (error) {
				this.errors = convertError(error.response, ['start_date', 'end_date']);
				let message = this.$t('failed_to_save');
				if (this.errors) {
					const firstKey = Object.keys(this.errors)[0];
					message = this.errors[firstKey];
				}

				window.alert(message);
				
				this.$log.error(error);
				this.cancelChanges();
				throw error;
			}

			this.$emit('update');

			this.isEditModeOn = false;
		},

		cancelChanges() {
			this.$set(this, 'tempInstance', deepCopy(this.innerInstance));
			this.isEditModeOn = false;
		},

		updateMapbox() {
			this.$emit('update');
		}
	},

	watch: {
		instanceJson() {
			const {
				max_bid,
				payment_model,
				budget,
				ad_duration,
				ad_rate_per_hour,
				start_date,
				end_date,
				time_range,
				schedule,
				age,
				gender,
				environments,
				resolutions,
				orientations,
				device_types,
				areas,
			} = this.instance;

			const innerInstance = Object.freeze({
				max_bid,
				payment_model,
				budget,
				ad_duration,
				ad_rate_per_hour,
				time_period: {
					start: start_date,
					end: end_date,
				},
				time_range,
				schedule,
				age,
				gender,
				environments,
				resolutions,
				orientations,
				device_types,
				areas,
			});

			const tempInstance = { ...innerInstance };

			this.innerInstance = deepCopy(innerInstance);
			this.tempInstance = deepCopy(tempInstance);
		},

		//  This watcher is needed to update stats from tempInstance
		tempInstance: {
			deep: true,
			handler(instance) {
				this.updateMapAndStats();
			},
		},
	},


	async created() {
		return await Promise.all([
			this.$store.dispatch('extra/getPaymentModels'),
			this.$store.dispatch('extra/getBroadcastTypes', {
				platforms: this.instance.platforms || 'all',
				force: true,
				is_deployed: true
			}),

			this.$asyncSet(this, 'environmentList', this.$store.dispatch('devices/getEnvironments', {
				platforms: this.instance.platforms || 'all',
				is_deployed: true
			})),
			this.$asyncSet(this, 'interestsAndActivitiesList', this.$store.dispatch('devices/getInterestsAndActivities')),
			this.$asyncSet(this, 'lifeStageList', this.$store.dispatch('devices/getLifeStages')),
			this.$asyncSet(this, 'intentList', this.$store.dispatch('devices/getIntents')),
			this.$asyncSet(this, 'resolutionList', this.$store.dispatch('devices/getResolutions', {
				platforms: this.instance.platforms || 'all',
				is_deployed: true
			})),
			this.$asyncSet(this, 'orientationList', this.$store.dispatch('devices/getOrientations')),
			this.updateMapAndStats(),
		]);
	},
};
</script>

<style lang="sass" scoped>
$width-limit: 330px

.wrapper
	flex-wrap: nowarp

.review-tab
	margin-top: -21px

.map-device
	height: 372px
	margin-bottom: 30px

.range-preview
	max-width: $width-limit

.line
	margin: 54px 0
	// max-width: $width-limit

.order-section-title
	font-size: 16px
	font-weight: normal
	line-height: 18px
	// margin: (30px - 18px) / 2 / 18px 0
	margin: 30px 0

.order-section-subtitle
	font-size: 14.5px
	font-weight: bold
	line-height: 18px
	margin: (30px - 18px) / 2 / 18px 0

.details .time_range-label
	margin-bottom: 0

.summary
	position: sticky
	top: 30px
	bottom: 30px


.details
	margin-bottom: -35px
	& > *
		margin-bottom: 35px

.detail
	display: flex
	flex-direction: column

.value
	display: flex
	align-items: center
	width: 100%
	line-height: 18px
	margin: (30px - 18px) / 2 0
	text-overflow: ellipsis

	&:not(.multiline)
		white-space: nowrap
		overflow: hidden

	& > *
		margin-left: .5em

	& > :first-child
		margin-left: 0

	& > .units,
	& > .shaded
		color: var(--text_2_color)


.detail-huge
	& > .label
		font-size: 11px

	& > .value
		font-weight: 300
		font-size: 16px

.detail-pad-right-33
	padding-right: 33%

.inline-edit-field
	display: inline-block
	width: 125px

	& ::v-deep .ni_input--units
		color: var(--text_2_color)


.inline-edit-field_huge
	margin-top: 2px

	&::v-deep
		.ni_label--label
			margin-bottom: 4px
			line-height: 24px
			font-size: 11px

		.ni_input--input,
		.ni_input--units
			font-weight: 300
			font-size: 16px

.edit-button
	display: flex
	justify-content: flex-end

.inline-edit-button
	width: 220px

	&_control
		width: 94px

.control-buttons
	display: flex
	justify-content: flex-end

	& > *
		margin-left: 16px

.frequency-limit
	position: relative

	&::before
		content: '<'
		color: $nice_color-darkgray
		position: absolute
		bottom: 9px
		left: -10px
.aside
	position: sticky
	top: 40px
	box-sizing: border-box
	padding-left: 20px

.aside__button-wrapper
	display: flex
	align-items: center
	margin-top: 20px

	& > *
		margin-right: 10px
		&:last-child
			margin-right: 0

.aside__modal-button
	flex-basis: 100%

::v-deep
	.mapboxgl-ctrl-geocoder.mapboxgl-ctrl
		visibility: hidden
		display: none
</style>
