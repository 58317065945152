/**
 * Module for request site api maked on django rest framework
 * TODO:
 *  * re request token move to interceptors and `logn`, `token_verify` and `token_refresh` move to another module
 *
 */
import Vue from 'vue';
import axios from 'axios';

import store from '@/store';
import router from '@/router';
import { objectToQuery, parseJwt } from '@/utilites';
import { objectToFormData } from 'object-to-formdata';


export const REQUEST_CODE_UNAUTHORIZED = 401;  // Unauthorized
export const REQUEST_CODE_FORBIDDEN = 403;  // Forbidden
export const REQUEST_DEFAULT_TIMEOUT = 30 * 1000; // 30 seconds


export const API_AUTH_HEADER = 'X-Authorization';



const CONFIG = {
	// see setBaseUrl below
	// baseURL: process.env.VUE_APP_API_URL,
	paramsSerializer: params => objectToQuery(params, false),
	timeout: REQUEST_DEFAULT_TIMEOUT,
};

// dev auth
if (process.env.VUE_APP_API_AUTH_HEADER) {
	CONFIG.headers = {
		Authorization: process.env.VUE_APP_API_AUTH_HEADER,
	};
}


/**
 * Set API baseURL
 */
const setBaseUrl = config => {
	config.baseURL = getBaseApiUrl();
	return config;
};


const setAcceptLanguageHeader = config => {
	config.headers['Accept-Language'] = store.state.profile.language;
	return config;
};


const setRoleHeader = config => {
	config.headers['x-adexchange-role'] = store.state.app.role;
	return config;
};


/**
 * User Auth
 * add Authorization header if token exists
 */
const setAuthorizationHeader = config => {
	if (
		!store.state.profile.token
		|| (
			(config.url.indexOf('http:') === 0 || config.url.indexOf('https:') === 0)
			&& config.url.indexOf(getBaseApiUrl()) !== 0
		)
	) {
		delete config.headers[API_AUTH_HEADER];
		return config;
	}

	// check `X-Authorization` token override
	if (config.headers && typeof(config.headers[API_AUTH_HEADER]) != 'undefined') {
		// delete if it is empty
		if (!config.headers[API_AUTH_HEADER]) {
			delete config.headers[API_AUTH_HEADER];
		}
	}
	else {
		config.headers[API_AUTH_HEADER] = `Bearer ${store.state.profile.token}`;
	}

	return config;
};

const adjustFormDataKeys = (formData) => {
	const updatedFormData = new FormData();

	for (const [key, value] of formData.entries()) {
		let newKey = key;
		if (key.endsWith('[]')) {
			newKey = key.substring(0, key.length - 2);
		}
		updatedFormData.append(newKey, value);
	}

	return updatedFormData;
};

/**
 * receives `data` and `conf`
 * returns formData if 'Content-Type' is 'multipart/form-data'
 */
const dataToFormDataMiddleware = (data, config) => {
	if (!config || !config.headers || config.headers['Content-Type'] !== 'multipart/form-data') {
		return data;
	}

	let formData = objectToFormData(data);
	formData = adjustFormDataKeys(formData);
	return formData;
};

const axios_request = axios.create(CONFIG);
axios_request.interceptors.request.use(setBaseUrl);
axios_request.interceptors.request.use(setAcceptLanguageHeader);
axios_request.interceptors.request.use(setAuthorizationHeader);
axios_request.interceptors.request.use(setRoleHeader);


/**
 * Get Base API URL
 *
 * Accounts for white-label
 */
export function getBaseApiUrl() {
	let url = process.env.VUE_APP_API_URL;

	// not base frontend - white-label
	if (process.env.VUE_APP_FRONT_BASE_URL.indexOf(window.location.origin) !== 0) {
		url = `${window.location.origin}${process.env.VUE_APP_WHITELABEL_API_URL}`;
	}

	return url;
}


/**
 * Request in person
 */
export async function send(resource, data, type, config) {

	let method = 'get';
	if (type) {
		method = type.toLowerCase();
	}

	switch(method) {
	case 'post':
		data = dataToFormDataMiddleware(data, config);
		return await axios_request.post(resource, data, config);

	case 'patch':
		data = dataToFormDataMiddleware(data, config);
		return await axios_request.patch(resource, data, config);

	case 'delete':
		return await axios_request.delete(resource, {
			...config,
			data, // wtf ak ?
		});

	default:
		return await axios_request.get(resource, {
			...config,
			params: data
		});
	}
}


/**
 * Request to api
 * It's wrapper for update token
 */
export async function request(resource, data, type, config) {
	const refresh_token = store.state.profile.refresh;
	const token = store.state.profile.token;
	const now = new Date();

	if (!token || parseJwt(token).exp < now) {
		Vue.$log.warn('Access token is probably expired or missing. Need to refresh.');

		// refresh
		if (!refresh_token || parseJwt(refresh_token).exp < now) {
			Vue.$log.error('Refresh token is probably expired or missing. Trying to use expired refresh token.');
		}

		try {
			const new_token = await token_refresh(store.state.profile.refresh);
			store.commit('profile/setToken', { token: new_token });
			return await request(resource, data, type, config);
		} catch (err) {
			// catch token update errors
			if (!err || typeof(err.response) === 'undefined' || err.response.status !== REQUEST_CODE_UNAUTHORIZED) {
				Vue.$log.error('Token refresh failed.');
				Vue.$log.error(err);
				store.commit('profile/setToken', {});
				// send user to auth here
				router.push({ name: 'login', query: { next: router.currentRoute.fullPath } });
				return;
			}
		}
	}

	const response = await send(resource, data, type, config);
	return response.data;

	// It is better to throw an exception then to hide it
	// try {
	// 	const response = await send(resource, data, type, config);
	// 	return response.data;
	// } catch (err) {
	// 	// log error if error
	// 	if (!err || typeof(err.response) === 'undefined' || err.response.status !== REQUEST_CODE_UNAUTHORIZED || !store.state.profile.refresh) {
	// 		Vue.$log.error(err);
	// 	}
	// }
}


/**
 * Request to login
 */
export async function login(email, password) {
	const response = await send('login/', {
		email: email,
		password: password
	}, 'post');

	return response.data;
}


export async function check2fa(token, code) {
	const response = await send('2fa/login/', {
		token: token,
		code: code,
	}, 'post');

	return response.data;
}

/**
 * Token verify
 * verify token 200 if token good else 401
 */
export async function token_verify(token) {
	try {
		await send('login/token/verify/', { token: token }, 'post');
		return true;
	} catch (err) {
		if (!err || typeof(err.response) === 'undefined' || err.response.status !== REQUEST_CODE_UNAUTHORIZED) {
			throw err;
		}

		return false;
	}
}


/**
 * Token refresh
 * return access token
 * If the refresh token has expired, throw error
 */
export async function token_refresh(token) {
	const response = await send('login/token/refresh/', { refresh: token }, 'post');
	return response.data.access;
}


/**
 * Get overrides for White-label domain
 */
export async function get_uioverrides() {
	return await send('/ui-overrides/', null, 'get', {
		headers: {
			// remove Auth Header - see api/requests.js `setAuthorizationHeader()`
			[API_AUTH_HEADER]: null,
		},
	});
}
