<template>
	<div>

		<nice-input
			v-if="!userExists"
			ref="first_name"
			v-model="user.first_name"
			:placeholder="$t('invitation.first_name')"
			:invalid="errors.first_name"
			type="text"
			class="input-field"
		/>
		<div v-if="errors.first_name" class="error">{{ errors.first_name }}</div>

		<nice-input
			v-if="!userExists"
			ref="last_name"
			v-model="user.last_name"
			:placeholder="$t('invitation.last_name')"
			:invalid="errors.last_name"
			type="text"
			class="input-field"
		/>
		<div v-if="errors.last_name" class="error">{{ errors.last_name }}</div>


		<nice-input
			v-if="doCreateAgency"
			ref="agency_name"
			v-model="user.agency_name"
			:placeholder="$t('invitation.organization_name')"
			:invalid="errors.agency_name"
			type="text"
			class="input-field"
		/>
		<div v-if="errors.agency_name" class="error">{{ errors.agency_name }}</div>


		<nice-input
			ref="email"
			v-model="user.email"
			:placeholder="$t('invitation.email')"
			:invalid="errors.email"
			type="text"
			class="input-field"
		/>
		<div v-if="errors.email" class="error">{{ errors.email }}</div>

		<nice-input
			ref="phone"
			v-model="user.phone"
			:placeholder="$t('invitation.phone_number')"
			:invalid="errors.phone"
			type="text"
			class="input-field"
		/>
		<div v-if="errors.phone" class="error">{{ errors.phone }}</div>


		<nice-input
			v-if="!userExists"
			ref="password"
			v-model="user.password"
			:placeholder="$t('invitation.password')"
			:invalid="errors.password"
			:type="showPassword ? 'text' : 'password'"
			class="password input-field"
		>
			<nice-button-2
				class="password-see-button"
				:class="{ 'show-password': showPassword }"
				icon="see-24"
				@click="togglePassword"
			/>
		</nice-input>
		<div v-if="errors.password" class="error">{{ errors.password }}</div>

		<nice-input
			v-if="!userExists"
			ref="password2"
			v-model="user.password2"
			:placeholder="$t('invitation.repeat_password')"
			:invalid="errors.password2"
			:type="showPassword ? 'text' : 'password'"
			class="password2 input-field"
		>
			<nice-button-2
				class="password-see-button"
				:class="{ 'show-password': showPassword }"
				icon="see-24"
				@click="togglePassword"
			/>
		</nice-input>
		<div v-if="errors.password2" class="error">{{ errors.password2 }}</div>

		<div class="help-container">
			<div 
				v-if="$uioverrides.help_phone || $uioverrides.help_email"
				class="help-block" 
				@click="showHelpModal"
			>
				<span class="help-text">{{ $t('common.help_is_needed') }}</span>
				<nice-icon-2 class="hand" icon="hand" />
			</div>
		</div>

		<div class="buttons">
			<nice-button-2
				filled
				icon="arrow-14"
				icon-state="-90"
				icon-placement="end"
				icon-painted
				@click="createAccount"
			>
				{{ $t('invitation.create_account') | capitalize }}
			</nice-button-2>
		</div>

		<div class="tos-wrapper">
			<nice-checkbox
				ref="tos_accepted"
				v-model="user.tos_accepted"
				:invalid="errors.tos_accepted"
				class="tos-checkbox"
			/>
			<a
				:href="tosFile"
				target="_blank"
			>
				{{ tosCaption }}
			</a>
		</div>
		<div v-if="errors.tos_accepted" class="error">{{ errors.tos_accepted }}</div>

	</div>
</template>


<script>
import { mapMutations } from 'vuex';
import { scrollToElementTop } from '@/utilites';

import NiceInput from '@/ui/nice-input';
import NiceCheckbox from '@/ui/nice-checkbox';
import { getMessage, nameAndLastNameValidator, phoneValidator } from '@/ui/utils/validators';


export default {
	name: 'RegistrationInvitationForm',


	components: {
		NiceInput,
		NiceCheckbox,
	},


	props: {
		doCreateAgency: {
			default: true,
		},
		userExists: {
			default: false,
		},
	},


	data() {
		return {
			user: {},
			errors: {},
			showPassword: false,
		};
	},

	computed: {
		/**
		 * TODO: return a default file
		 */
		tosFile() {
			return this.$uioverrides.tos_file || process.env.VUE_APP_DEFAULT_TOS_FILE;
		},

		/**
		 * TODO: return a default caption
		 */
		tosCaption() {
			return this.$uioverrides.tos_caption || this.$t('invitation.tos_caption');
		},
	},

	methods: {
		...mapMutations('modals', ['setShowHelpModal']),

		togglePassword() {
			this.showPassword = !this.showPassword;
		},

		createAccount() {
			if (!this.checkFields()) {
				return;
			}

			this.$emit('form-submit', this.user, this);
		},


		checkFields() {
			// clear errors
			this.$set(this, 'errors', {});

			let errors = {};

			if (!this.userExists) {
				if (!this.user.first_name) {
					errors.first_name = this.$t('invitation.first_name_cant_be_empty');
				} else {
					const message = getMessage(nameAndLastNameValidator, this.user.first_name);
					if (message) {
						errors.first_name = message;
					}
				}

				if (!this.user.last_name) {
					errors.last_name = this.$t('invitation.last_name_cant_be_empty');
				} else {
					const message = getMessage(nameAndLastNameValidator, this.user.last_name);
					if (message) {
						errors.last_name = message;
					}
				}

				if (!this.user.password) {
					errors.password = this.$t('invitation.password_cant_be_empty');
				}

				if (this.user.password !== this.user.password2) {
					errors.password2 = this.$t('invitation.passwords_mismatch');
				}
			}


			if (this.doCreateAgency && !this.user.agency_name) {
				errors.agency_name = this.$t('invitation.agency_name_cant_be_empty');
			}

			if (!this.user.email) {
				errors.email = this.$t('invitation.email_cant_be_empty');
			}

			if (!this.user.phone) {
				errors.phone = this.$t('invitation.phone_cant_be_empty');
			} else {
				const message = getMessage(phoneValidator, this.user.phone);
				if (message) {
					errors.phone = message;
				}
			}

			if (!this.user.tos_accepted) {
				errors.tos_accepted = this.$t('invitation.tos_not_accepted');
			}

			this.$set(this, 'errors', errors);

			this.goToTheFirstError();

			if (Object.keys(errors).length > 0) {
				return false;
			}

			return true;
		},

		goToTheFirstError() {
			let errorKeys = Object.keys(this.errors);
			if (errorKeys.length > 0) {
				const firstErrorEl = this.$refs[errorKeys[0]];
				if (firstErrorEl) {
					scrollToElementTop(firstErrorEl.$el);
				}
			}
		},

		showHelpModal() {
			this.setShowHelpModal(true);
		},
	},  // END: methods
};
</script>


<style lang="sass" scoped>
.icon
	margin-right: 12px

.nice-input:first-child
	margin-top: 0

.nice-input
	margin-top: 30px

.nice-input ::v-deep input
	height: 15px
	padding: 1px 1px 6px 1px

.buttons
	margin-top: 42px
	display: flex
	flex-direction: row
	align-items: center
	justify-content: flex-end

	.nice-button
		width: 100%
		min-height: 35px

.tos-wrapper
	display: flex
	flex-direction: row
	margin-top: 25px

	.tos-checkbox
		align-items: flex-start

		::v-deep .ni-chbx-caption
			font-size: 10.5px
			line-height: 140%
			text-overflow: initial
			white-space: initial

	a
		margin-left: 9px
		font-size: 10.5px
		line-height: 140%
		color: $nice_color-darkgray
		text-decoration: none

		&:hover
			text-decoration: underline

.help-container
	position: relative
	margin-top: 42px
	height: 18px
	line-height: 18px
	color: var(--text_2_color)
	font-size: 11px
	font-weight: 700
	text-transform: uppercase

	.help-block
		cursor: pointer
	
	.hand
		position: absolute
		left: 0
		width: 18px
	
	.help-text
		display: block
		float: left
		padding-left: 28px
		font-size: 11px
		line-height: 18px
		font-weight: 700
		text-transform: uppercase
	
	.login-button
		min-height: auto
		float: right
		padding: 0
		line-height: 18px
		text-align: right
		background: none
		border: none
		cursor: pointer
		
.error
	color: red
	margin-top: 5px

::v-deep .password input, ::v-deep .password2 input
	padding-right: 22px

.password-see-button
	position: absolute
	right: 0
	width: 20px
	top: -5px
	border-radius: 0

.show-password
	opacity: 0.75

</style>
